<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
        <div class="container-fluid">
            <div class="d-flex" id="top_header_logo_name">
                <router-link class="navbar-brand" to="/">
                    <img src="@/assets/website_logo.svg" class="website_logo" alt="">
                </router-link>
                <p class="company_name ps-md-5 d-none d-lg-block">
                    {{ myStore.commercialCompanyName }} ({{ customerNumber }})
                </p>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" @click="hamburger = !hamburger"></span>
            </button>
            <div class="collapse navbar-collapse" :class="hamburger == true ? 'show' : ''" id="navbarSupportedContente">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item me-lg-3">
                        <div class="dropdown mt-3 mt-lg-0 mb-2">
                            <button class="booking_form w-100" @click="createEnquiry">
                                Create an enquiry
                            </button>
                        </div>
                    </li>
                    <li class="nav-item me-lg-3">
                        <div class="dropdown mb-2">
                            <button class="help_button w-100 me-2" :title="titleText" @click="helpOption = !helpOption">
                                Contact
                            </button>
                            <ul class="dropdown-menu mt-2" :class="helpOption == true ? 'show' : ''">
                                <li><a class="dropdown-item" :href="'tel:' + phoneNumber">Phone: {{ phoneNumber }}</a></li>
                                <li><a class="dropdown-item" :href="'mailto:' + email">Email: {{ email }}</a></li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown">
                            <button class="logout_button w-100" @click="logout">
                                LOGOUT
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import { useMyStore } from '@/store';
    import axios from 'axios';
    export default{
        data(){
            return {
                myStore: useMyStore(),
                phoneNumber: '',
                email: '',
                titleText: '',
                helpOption: false,
                hamburger: false,
                customerNumber: '',
            }
        },
        methods: {
            logout() {
                // Remove user details from local storage
                localStorage.removeItem('commercials_user_data');

                // Redirect the user to the login page
                this.$router.push('/commercial/login');
            },
            createEnquiry() {
                this.$router.push('/commercial/book');
            },
        },
        async mounted() {
            let user_details = localStorage.getItem('commercials_user_data');
            user_details = JSON.parse(user_details);
            console.log('user details: -> ', user_details);
            let user_id = user_details.uid;
            if (this.myStore.commercial_permissions == null) {
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
                    this.myStore.commercial_permissions = response;
                }).catch(error=>{
                    console.log('error', error);
                });
            }

            if (this.myStore.commercial_permissions) {
                this.myStore.commercialCompanyName = this.myStore.commercial_permissions.data.data.accountName;
            }

            let temp_response = [];
            if (this.myStore.main_dashboard == null) {
                await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
                    this.myStore.main_dashboard = response;
                    temp_response = this.myStore.main_dashboard;
                }).catch(error=>{
                    console.log('error', error);
                });
            } else {
                temp_response = this.myStore.main_dashboard;
            }
            temp_response.data.data.map((item)=>{
                console.log(item);
                if (item.type) {
                    if (item.type[0] == 'footer' && item.tag == 'commercial_dashboard_phone') {
                        this.phoneNumber = item.content;
                    }
                    if (item.type[0] == 'footer' && item.tag == 'commercial_dashboard_email') {
                        this.email = item.content;
                    }
                }
            });

            if (this.myStore.info == null) {
                let user_details = localStorage.getItem('commercials_user_data');
                user_details = JSON.parse(user_details);
                console.log('user details: -> ', user_details);
                let user_id = user_details.uid;
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/info`).then(response=>{
                    this.myStore.info = response;
                    // console.log('endpoint_data', this.endpointData);
                }).catch(error=>{
                    console.log('error occured in server', error);
                    this.$emit('triggerFunction');
                });
            }

            const companyInfoSection = this.myStore.info?.data.data.find(
                section => section.section === "companyInformation"
            );

            if (companyInfoSection) {
                const customerNumberField = companyInfoSection.fields.find(
                field => field.fieldName === "customerNumber"
                );

                if (customerNumberField) {
                this.customerNumber = customerNumberField.value;
                }
            }


            this.titleText = 'Phone: ' + this.phoneNumber + ', ' + 'Email: ' + this.email;
        },
    }
</script>

<style scoped>
    .logout_button{
        background: #FFE7E0;
        color: #D41866;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }

    .help_button{
        background: #c5c5c5;
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }

    .hamburger{
        background-color: #ffffff;
        color: #000;
        border: none;
        height: 40px;
        width: 40px;
    }
    .hamburger i{
        font-size: 28px;
        color: #06CCCC;
    }

    .company_name_col{
        align-content: center;
    }

    .dropdown{
        position: relative;
    }

    .dropdown-menu{
        right: 0;
    }

    .align_content_center .dropdown-menu{
        left: 0;
    }

    .company_name{
        font-family: Poppins;
        font-size: 28px;
        font-weight: 800;
        line-height: 31.68px;
        text-align: left;
        margin-bottom: 0;
        align-content: center;
    }

    .align_content_center{
        align-content: center;
    }
    
    .booking_form{
        background-color: #06CCCC;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }

    @media(max-width:764px) {
        .website_logo{
            height: 40px;
            width: 150px;
        }
    }
</style>