<template>
    <div class="min_75h" v-if="!noDataFound">
        <div class="col-12 col-xl-12 p-0 p-xl-5 pt-0 pt-xl-0 pb-0 pb-xl-0" id="cardsCollectionContainer">
            <div class="row" v-if="allCardDetails.length > 0">
                <div class="col-12 col-xl-3 mb-3" v-for="(value, key) in allCardDetails" :key="key">
                    <div class="card custom_card p-2">
                        <div class="card-body">
                            <p class="card_title">
                                {{ getLabel(value.title) }}
                            </p>
                            <h2 class="card_content">
                                {{ value.title.includes('£') ? getLabel3(value.title) : '' }}
                                <NumberAnimation
                                    :from="0"
                                    :to="value.value"
                                    :format="formatNumberInteger"
                                    :duration="1"
                                    easing="linear"
                                />
                                {{ value.title.includes('£') ? '' : getLabel2(value.title) }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col"></div>
            <div class="col-auto">
                <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsSort">
                    <i class="bi bi-sort-down"></i> <b>Sort</b>
                </button>

                <div class="modal fade" id="jobsSort" tabindex="-1" aria-labelledby="jobsSortLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsSortLabel">Sort Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>
                                    Select Column
                                </label>
                                <select class="form-select" v-model="selectedSortColumn">
                                    <option :value="option" v-for="(option,index) in sortColumnsCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    Select Order
                                </label>
                                <select class="form-select" v-model="sort_order">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearSort()">Clear Sort</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performSort()" data-bs-dismiss="modal">Apply Sort</button>
                        </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-custom" type="button" data-bs-toggle="modal" data-bs-target="#billingFilter">
                    <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                </button>

                <div class="modal fade" id="billingFilter" tabindex="-1" aria-labelledby="billingFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" style="width: 60vw;max-width: 60vw;">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="billingFilterLabel">Filter Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="width: 60vw;max-width: 60vw;">
                            <div class="form-group mb-3">
                                <select class="form-select" v-model="selectedStatus">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>Date Type:</label>
                                <select class="form-select" v-model="selectedDateType">
                                    <option :value="item.id" v-for="(item, index) in dateOption" :key="index">
                                        {{ item.title }}
                                    </option>
                                </select>

                                <!-- <div class="row p-2">
                                    <div class="col">
                                        <label>
                                            From Date:
                                        </label>
                                        <input type="date" class="form-control mb-3" v-model="selectedFrom">
                                    </div>
                                    <div class="col">
                                        <label>
                                            To Date:
                                        </label>
                                        <input type="date" class="form-control mb-3" v-model="selectedTo">
                                    </div>
                                </div> -->
                            </div>

                            <hr>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-xl-5 col-12">
                                        <ul class="list-group filter_time_options">
                                            <li class="list-group-item" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">This Month</li>
                                            <li class="list-group-item" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">Last Month</li>
                                            <li class="list-group-item" :class="checkThisQuarterSelected() ? 'active' : ''" @click="fetchThisQuarterData()">This Quarter</li>
                                            <li class="list-group-item" :class="checkLastQuarterSelected() ? 'active' : ''" @click="lastQuarterData()">Last Quarter</li>
                                            <li class="list-group-item" :class="checkThisYearSelected() ? 'active' : ''" @click="fetchThisYearData()">This Year</li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-7 col-12">
                                        <div class="form-group mb-3">
                                            <label>Select Months</label>
                                            <Calendar
                                                v-model:checkIn="checkIn" 
                                                v-model:checkOut="checkOut"
                                                :alwaysVisible="true"
                                                locale="en"
                                                :bookingColor="bookingColor"
                                                :disabledDaysBeforeDayDate="false"
                                                :placeholder="calendarPlaceholder"
                                            ></Calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilter()" data-bs-dismiss="modal">Apply Filter</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-container">
            <div class="table-responsive tableFixHead" :class="allCardDetails.length <= 4 ? 'fixHeight55vh' : ''">
                <table class="table">
                    <thead>
                        <tr>
                            <th v-for="(field, index) in titlesCollection" :key="index" scope="col">{{ field.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in billingInfos" :key="index">
                            <td v-for="(field, idx) in row" :key="idx">
                                <div v-if="field.fieldName == 'paymentStatus'" class="dynamic_bubble" :class="field.value == 'PAID' ? 'green' : field.value == 'OVERDUE' ? 'red' : field.value == 'DUE' ? 'yellow' : ''">
                                    {{ field.value }}
                                </div>
                                <template v-else>
                                    <span class="cursor_pointer" v-if="field.type == 'currency'" title="The total sum of the collection charges including VAT">
                                        {{ field.value == null ? ' - ' : ((field.fieldName === 'amount') ? '£ ' : '') + addCommasTwoDecimal(field.value) }}
                                    </span>
                                    <a :href="field.linkTo" v-if="field.linkTo" target="_blank">
                                        {{ field.value }}
                                    </a>
                                    <span v-else-if="field?.type == 'text'">
                                        {{ field.value == null ? '-' : addCommas(field.value) }}
                                    </span>
                                    <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                        {{ field.value }}
                                    </div>
                                    <p v-if="field.type == 'link' && field.value == null">
                                        <button class="btn btn-secondary w-100 text-center">
                                            Document
                                        </button>
                                    </p>
                                    <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                        <button class="btn btn-primary w-100 text-center">
                                            Document
                                        </button>
                                    </a>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th v-for="(field, index) in titlesCollection" :key="index" scope="col">
                                <!-- <hr> -->
                                {{ field.fieldName == 'amount' ? 'Total: £ ' + formatNumber(amountSum) : '' }}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import NumberAnimation from "vue-number-animation";
    import axios from 'axios';
    import { Calendar } from "vue-calendar-3";

    export default {
        data(){
            return {
                billingInfos: [],
                myStore: useMyStore(),
                titlesCollection: [],
                dateOption: [],
                selectedStatus: '',
                statusCollection: [],
                selectedFrom: '',
                selectedTo: '',
                selectedDateType: '',
                amountSum: 0,
                allCardDetails: [],
                noDataFound: false,
                selectedSortColumn: 'Job Date',
                sortColumnsCollection: [
                    'Job Date',
                    'Invoice Date',
                    'Invoice Due Date',
                    'Invoice Amount',
                ],
                sort_order: 'DESC',
                checkIn: null,
                checkOut: null,
                bookingColor: {
                    admin: "#06CCCC",
                    contract: "#06CCCC",
                },
                calendarPlaceholder: { checkIn: "From", checkOut: "To", },
            }
        },
        components: {
            NumberAnimation,
            Calendar
        },
        methods: {
            clearSort(){
                this.selectedSortColumn = 'Job Date';
                this.sort_order = 'DESC';
                this.performSort();
            },
            parseDateTime(record,fieldname) {
                const dateField = record.find(item => item.fieldName === fieldname);

                if (dateField.value == 'Pending'){
                    return null;
                }

                if (!dateField || !dateField.value) {
                    return null; // Return null if date value is missing
                }

                const dateValue = dateField.value;

                // Split the date string by '/' and convert it to the correct format (MM/DD/YYYY)
                const [day, month, year] = dateValue.split('/');
                const formattedDate = `${month}/${day}/${year}`;

                return new Date(formattedDate);
            },
            performSort() {
                if (!this.selectedSortColumn || !this.sort_order) {
                    return; // No sort column or order selected, exit function
                }

                // Define a custom sort function based on the selected sort column and order
                let customSortFunction;

                switch (this.selectedSortColumn) {
                    case 'Job Date':
                        customSortFunction = (a, b) => {
                            const dateA = this.parseDateTime(a,'jobCompletionDate');
                            const dateB = this.parseDateTime(b,'jobCompletionDate');

                            if (dateA === null && dateB === null) {
                                return 0;
                            } else if (dateA === null) {
                                return 1;
                            } else if (dateB === null) {
                                return -1;
                            }

                            return this.sort_order === 'ASC' ? dateA - dateB : dateB - dateA;
                        };
                        break;
                    case 'Invoice Date':
                        customSortFunction = (a, b) => {
                            const dateA = this.parseDateTime(a,'invoiceDate');
                            const dateB = this.parseDateTime(b,'invoiceDate');

                            if (dateA === null && dateB === null) {
                                return 0;
                            } else if (dateA === null) {
                                return 1;
                            } else if (dateB === null) {
                                return -1;
                            }

                            return this.sort_order === 'ASC' ? dateA - dateB : dateB - dateA;
                        };
                        break;
                    case 'Invoice Due Date':
                        customSortFunction = (a, b) => {
                            const dateA = this.parseDateTime(a,'dueDate');
                            const dateB = this.parseDateTime(b,'dueDate');

                            if (dateA === null && dateB === null) {
                                return 0;
                            } else if (dateA === null) {
                                return 1;
                            } else if (dateB === null) {
                                return -1;
                            }

                            return this.sort_order === 'ASC' ? dateA - dateB : dateB - dateA;
                        };
                        break;

                    case 'Invoice Amount':
                        customSortFunction = (a, b) => {
                            const amountAValue = a.find(item => item.fieldName === 'amount').value;
                            const amountBValue = b.find(item => item.fieldName === 'amount').value;

                            const amountAIsNumeric = !isNaN(parseFloat(amountAValue)) && isFinite(amountAValue);
                            const amountBIsNumeric = !isNaN(parseFloat(amountBValue)) && isFinite(amountBValue);

                            // If one value is numeric and the other isn't, sort the numeric one first
                            if (amountAIsNumeric && !amountBIsNumeric) {
                                return -1;
                            } else if (!amountAIsNumeric && amountBIsNumeric) {
                                return 1;
                            }

                            // If both values are numeric, proceed with numeric comparison
                            if (amountAIsNumeric && amountBIsNumeric) {
                                const amountA = parseInt(amountAValue);
                                const amountB = parseInt(amountBValue);
                                return this.sort_order === 'ASC' ? amountA - amountB : amountB - amountA;
                            }

                            // If neither value is numeric, consider them equal
                            return 0;
                        };
                        break;

                    default:
                        return; // Unsupported sort column, exit function
                }

                // Sort billingInfos using the custom sort function
                this.billingInfos.sort(customSortFunction);
            },
            formatNumberCard(value) {
                // Convert the value to an integer
                return Math.floor(value);
            },
            getLabel(key) {
                // const words = key.split(/(?=[A-Z])/);
                // const label = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                // return label;

                const index = key.indexOf('(');
                if (index !== -1) {
                    // If parentheses are found, return the substring before the parentheses
                    return key.substring(0, index).trim();
                } else {
                    // If no parentheses are found, return the original key
                    return key;
                }
            },
            getLabel2(key) {
                const startIndex = key.indexOf('(');
                const endIndex = key.indexOf(')');
                if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                    // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                    return ' ' + key.substring(startIndex + 1, endIndex).trim();
                } else {
                    // If no parentheses are found or they are in the wrong order, return an empty string
                    return '';
                }
            },
            getLabel3(key) {
                const startIndex = key.indexOf('(');
                const endIndex = key.indexOf(')');
                if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                    // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                    return key.substring(startIndex + 1, endIndex).trim() + ' ';
                } else {
                    // If no parentheses are found or they are in the wrong order, return an empty string
                    return '';
                }
            },
            clearFilter() {
                this.billingInfos = this.myStore.billing.data.data.table;
                this.calculateSum();
                this.selectedStatus = '';
                this.selectedFrom = '';
                this.selectedTo = '';
                this.selectedDateType = this.dateOption[0].id;
                this.performSort();
                this.checkIn = null;
                this.checkOut = null;
            },
            performFilter() {
                let temp_data_collection = [];
                if (this.selectedStatus != '') {
                    temp_data_collection =  this.myStore.billing.data.data.table.filter((item)=>{
                        let temp_checking = item.find(item2 => item2.fieldName == 'status');
                        if (temp_checking.value == this.selectedStatus) {
                            return item;
                        }
                    });
                } else {
                    temp_data_collection = this.myStore.billing.data.data.table;
                }

                if (this.selectedFrom != '' && this.selectedTo != '') {
                    let selected_date_type = this.dateOption.find(item => item.id == this.selectedDateType);
                    const fromDate = new Date(this.selectedFrom);
                    const toDate = new Date(this.selectedTo);
                    const filteredData = temp_data_collection.filter(item => {
                        const completionDate = item.find(item2 => item2.fieldName == selected_date_type.fieldName);
                        if (completionDate) {
                            if (completionDate.value != null && completionDate.value != 'TBC') {
                                const itemDate = this.parseDate(completionDate.value);
                                return itemDate >= fromDate && itemDate <= toDate;
                            } else {
                                console.log(completionDate);
                            }
                        } else {
                            console.log(completionDate);
                        }
                        return false;
                    });
                    console.log(selected_date_type.fieldName);
                    temp_data_collection = filteredData;
                }

                if (this.checkIn && this.checkOut) {
                    let selected_date_type = this.dateOption.find(item => item.id == this.selectedDateType);
                    const fromDate = new Date(this.checkIn);
                    const toDate = new Date(this.checkOut);
                    const filteredData = temp_data_collection.filter(item => {
                        const completionDate = item.find(item2 => item2.fieldName == selected_date_type.fieldName);
                        if (completionDate) {
                            if (completionDate.value != null && completionDate.value != 'TBC') {
                                const itemDate = this.parseDate(completionDate.value);
                                return itemDate >= fromDate && itemDate <= toDate;
                            } else {
                                console.log(completionDate);
                            }
                        } else {
                            console.log(completionDate);
                        }
                        return false;
                    });
                    console.log(selected_date_type.fieldName);
                    temp_data_collection = filteredData;
                }

                this.billingInfos = temp_data_collection;
                this.calculateSum();

                // document.getElementById('billingFilter').style.display = 'none';
                // const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                // modalBackdrops.forEach(modalBackdrop => {
                //     modalBackdrop.remove();
                // });
                this.performSort();
            },
            parseDate(dateString) {
                // let datePart;
                // if (dateString.includes('T')) {
                //     // Splitting the date string by 'T' to separate date and time parts
                //     [datePart,] = dateString.split('T');
                // } else {
                //     datePart = dateString;
                // }
                // const [year, month, day] = datePart.split('-');
                // console.log('parsed date: ', new Date(year, month - 1, day));
                // return new Date(year, month - 1, day);

                const [day, month, year] = dateString.split('/');
                console.log('parsed date: ', new Date(year, month - 1, day));
                return new Date(year, month - 1, day);
            },
            addCommas(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toLocaleString();
                } else {
                    return value;
                }
            },
            addCommasTwoDecimal(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toFixed(2).toLocaleString();
                } else {
                    return value;
                }
            },
            calculateSum(){
                let temp_sum = 0;
                this.billingInfos.forEach(item => {
                    let temp_amount = item.find(item2 => item2.fieldName == 'amount');
                    if (!isNaN(temp_amount.value) && temp_amount.value != null && temp_amount.value != 'TBC') {
                        temp_sum += parseFloat(temp_amount.value);
                    }
                });
                this.amountSum = temp_sum;
            },
            formatNumber(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val) {
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return integerPart
            },
            checkThisQuarterSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3);

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },
            checkLastQuarterSelected() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                let lastQuarter = Math.floor(currentMonth / 3) - 1;

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire last quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },
            checkThisYearSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1);
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire year is selected
                return checkInDate.getTime() === yearStart.getTime() && 
                    checkOutDate.getTime() === yearEnd.getTime();
            },
            checkMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth(); // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },
            checkLastMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth()-1; // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },
            fetchThisMonthData() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                this.checkIn = new Date(currentYear, currentDate.getMonth(), 1).toISOString();
                this.checkOut = new Date(currentYear, currentDate.getMonth() + 1, 0, 23, 59, 59).toISOString();
            },
            lastMonthData() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                this.checkIn = new Date(lastMonthYear, lastMonthMonth, 1).toISOString();
                this.checkOut = new Date(lastMonthYear, lastMonthMonth + 1, 0, 23, 59, 59).toISOString();
            },
            fetchThisQuarterData() {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3); // Get the current quarter (0, 1, 2, or 3)

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const currentYear = currentDate.getFullYear();
                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },
            lastQuarterData() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                
                let lastQuarter = Math.floor(currentMonth / 3) - 1; // Get the last quarter (0, 1, 2, or 3)

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },
            fetchThisYearData() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1).toISOString();
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59).toISOString();

                this.checkIn = yearStart;
                this.checkOut = yearEnd;
            },
        },
        async mounted(){
            this.myStore.showFooter = false;
            if (this.myStore.billing == null) {
                let user_details = localStorage.getItem('commercials_user_data');
                user_details = JSON.parse(user_details);
                console.log('user details: -> ', user_details);
                let user_id = user_details.uid;
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/billing`).then(response=>{
                    this.myStore.billing = response;
                }).catch(error=>{
                    console.log('error: ', error);
                    this.$emit('triggerFunction');
                });
            }

            if (!this.myStore.billing){
                this.noDataFound = true;
                this.$emit('triggerFunction');
            }
            this.titlesCollection = this.myStore.billing.data.data.table[0].map(item => item);
            this.billingInfos = this.myStore.billing.data.data.table;
            this.performSort();
            this.allCardDetails = this.myStore.billing.data.data.cards;
            this.calculateSum();
            console.log('datas from billing:', this.billingInfos);
            this.$emit('triggerFunction');

            let temp_option = this.myStore.billing.data.data.table[0].find(item => item.fieldName == 'jobCompletionDate');
            temp_option.id = 1;
            this.dateOption.push(temp_option);
            temp_option = this.myStore.billing.data.data.table[0].find(item => item.fieldName == 'invoiceDate');
            temp_option.id = 2;
            this.dateOption.push(temp_option);
            this.selectedDateType = this.dateOption[0].id;

            this.myStore.billing.data.data.table.map((item)=>{
                item.map((item2)=>{
                    if (item2.fieldName == 'status') {
                        if (!this.statusCollection.includes(item2.value)){
                            this.statusCollection.push(item2.value);
                        }
                    }
                });
            });
            this.fetchThisMonthData();
        },
    }
</script>

<style scoped>
    .min_75h{
        min-height: 75vh;
    }
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }
    

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    table { border-collapse: collapse; width: 100%; }
    th, td { background: #fff; padding: 8px 16px; }


    .tableFixHead {
        overflow: auto;
        height: 40vh;
    }

    .fixHeight55vh{
        height: 55vh;
    }

    .tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #eeeeee;
    }

    .tableFixHead tfoot th {
        position: sticky;
        bottom: -5px;
        z-index: 1; /* Ensure the footer is on top of other content */
        background-color: #eeeeee;
    }

    th {
        white-space: nowrap;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    .dynamic_bubble{
        color: #fff;
        padding: 5px 10px;
        text-align:center;
        border-radius: 10px;
    }

    .btn-custom{
        background-color: #fff;
        border: 1px solid #000;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .card_title{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .dynamic_bubble.green{
        background-color: rgb(16, 146, 30);
    }

    .dynamic_bubble.red{
        background-color: rgb(221, 44, 90);
    }

    .dynamic_bubble.yellow{
        background-color: rgb(218, 214, 29);
    }

    .filter_time_options li:hover{
        cursor: pointer;
    }

    .filter_time_options li.active{
        background-color: #06CCCC;
    }
</style>