<template>
    <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
    <form @submit.prevent="formSubmit" class="m-0 m-lg-auto form_booking">
        <div class="row p-3 p-xl-5 pt-3 pt-xl-5 pb-xl-0">
            <div class="col-lg-12">
                <div class="form-group mb-3 mb-xl-4">
                    <label>Booking Type <span class="text-danger">*</span></label>
                    <div class="row">
                        <button type="button" class="custom_option col m-2" :class="selectedOption == 1 ? 'active' : ''" @click="selectedOption = 1">Create a quote</button>
                        <button type="button" class="custom_option col m-2" :class="selectedOption == 2 ? 'active' : ''" @click="selectedOption = 2">Make a booking</button>
                    </div>
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Purchase order number: </label>
                    <input type="text" class="form-control" placeholder="Purchase order number" v-model="purchaseOrderNumber">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4" @dragover.prevent @drop.prevent>
                    <label for="purchase_order_file" class="w-100">
                        Upload purchase order:
                        <div class="photo_upload_div text-center w-100" @drop="uploadFileDrag">
                            <i class="bi bi-plus-circle-fill"></i>
                            <p><span class="gray-drag">Drag and drop here or</span> click to browse</p>
                        </div>
                    </label>
                    <input type="file" name="" class="d-none" id="purchase_order_file" @change="uploadFile" multiple>
                </div>
                <div class="row mb-3 mb-xl-4" v-if="purchaseOrderArray.length > 0">
                    <div class="col-md-3" v-for="(item, index) in purchaseOrderArray" :key="index">
                        <a :href="item" target="_blank" rel="">
                            <img :src="item" class="custom_image" v-if="determineFileType(item) == 'image'" alt="">
                            <img src="@/assets/file_logo/pdf_logo.png" class="custom_image" v-else-if="determineFileType(item) == 'pdf'" alt="">
                            <img src="@/assets/file_logo/doc_logo.png" class="custom_image" v-else-if="determineFileType(item) == 'word'" alt="">
                        </a>
                    </div>
                </div>

                <div v-show="selectedOption === 1" class="form-group mb-3 mb-xl-4" @dragover.prevent @drop.prevent>
                    <label for="photo_of_waste_file" class="w-100">
                        Photos of the waste:
                        <div class="photo_upload_div text-center w-100" @drop="uploadFile2Drag">
                            <i class="bi bi-plus-circle-fill"></i>
                            <p><span class="gray-drag">Drag and drop here or</span> click to browse</p>
                        </div>
                    </label>
                    <input type="file" name="" class="d-none" id="photo_of_waste_file" @change="uploadFile2" multiple>
                </div>

                <div class="row mb-3 mb-xl-4" v-if="photosOfWasteArray.length > 0">
                    <div class="col-md-3" v-for="(item, index) in photosOfWasteArray" :key="index">
                        <a :href="item" target="_blank" rel="">
                            <img :src="item" class="custom_image" v-if="determineFileType(item) == 'image'" alt="">
                            <img src="@/assets/file_logo/pdf_logo.png" class="custom_image" v-else-if="determineFileType(item) == 'pdf'" alt="">
                            <img src="@/assets/file_logo/doc_logo.png" class="custom_image" v-else-if="determineFileType(item) == 'word'" alt="">
                        </a>
                    </div>
                </div>

                <div v-show="selectedOption === 2" class="row">
                    <div class="col-lg-12">
                        <div class="form-group mb-3 mb-xl-4">
                            <label>Waste type:</label>
                            <input type="text" class="form-control" placeholder="Waste type" v-model="wasteType">
                        </div>
                    </div>
                </div>

                <div v-show="selectedOption === 2" class="row">
                    <div class="col-lg-12">
                        <div class="form-group mb-3 mb-xl-4">
                            <label>EWC code:</label>
                            <input type="text" class="form-control" placeholder="EWC code" v-model="ewcCode">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Producer of waste entity name:</label>
                    <input type="text" class="form-control" placeholder="Broker's client name" v-model="wasteEntityName">
                </div>

                <div v-show="selectedOption === 1" class="form-group mb-3 mb-xl-4">
                    <label>Email address: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Email address" v-model="emailAddress">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Name of the person making the booking: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Name" v-model="namePerson">
                </div>

                <div v-show="selectedOption === 1" class="form-group mb-3 mb-xl-4">
                    <label>Address of waste: <span class="text-danger">*</span></label>
                    <div class="p_relative w-100">
                        <input type="text" class="form-control" placeholder="Start typing the address.." v-model="searchTerm" @blur="hideAdressSuggestions()" @input="fetchAutoFillAddress()">
                        <div class="drop_down_options" v-if="showAddressSuggestions">
                            <ul class="list-group">
                                <li class="list-group-item" v-for="(item,index) in addressOptions" :key="index" @click="selectedAdressSuggestion(item)">{{ item.address }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div  class="form-group mb-3 mb-xl-3" v-if="showAddressLines">
                    <label>Address Lines:</label>
                    <input type="text" class="form-control mb-2" placeholder="Line 1" v-model="addressLine1">
                    <input type="text" class="form-control mb-2" placeholder="Line 2" v-model="addressLine2">
                    <input type="text" class="form-control mb-2" placeholder="Line 3" v-model="addressLine3">
                    <input type="text" class="form-control mb-2" placeholder="Line 4" v-model="addressLine4">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>On site contact name: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Name" v-model="siteContactName">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>On site contact number: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Mobile number" v-model="siteContactNumber">
                </div>

                <div v-show="selectedOption === 1" class="form-group mb-3 mb-xl-4">
                    <label>Describe the clearance requirements <span class="text-danger">*</span></label>
                    <textarea class="form-control" id="" cols="30" rows="5" placeholder="Clearance description" v-model="description"></textarea>
                </div>
            </div>

            <div class="col-lg-12">
                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Select load size: <span class="text-danger">*</span></label>
                    <select class="form-select" v-model="selectedLoadSize" @change="toggleLoadSize">
                        <option value="">Select a load</option>
                        <option :value="item" v-for="(item, index) in loadsOptions" :key="index">{{ item.litta_service_linked }}</option>
                    </select>
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Select any hazardous items (if required):</label>
                    <select class="form-select" @change="hazardusSelected" v-model="hazardusItem">
                        <option value="">Select a hazardous item</option>
                        <option :value="item" v-for="(item, index) in hazardousOptions" :key="index">{{ item.litta_service_linked }}</option>
                    </select>
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Selected loads/items:</label>
                    <input type="text" class="form-control" disabled v-if="items.length == 0 && selectedLoadOption == ''" placeholder="No items selected...">
                    <ul class="list-group" v-else>
                        <li class="list-group-item" v-if="selectedLoadOption">
                            <div class="row">
                                <div class="col align-content-center">
                                    {{ selectedLoadOption.litta_service_linked }}
                                </div>

                                <div class="col-auto">
                                    <div class="buttons_custom">
                                        <button type="button" class="btn btn-danger" @click="removeLoadSize">-</button>
                                        <span class="item_count">1 X</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" v-for="(item,index) in items" :key="index">
                            <div class="row">
                                <div class="col align-content-center ">
                                    {{ item.litta_service_linked }}
                                </div>

                                <div class="col-auto">
                                    <div class="buttons_custom">
                                        <button type="button" class="btn btn-danger" @click="decreseItemCount(index)">-</button>
                                        <span class="item_count">{{ item.count }}</span>
                                        <button type="button" class="btn btn-success" @click="increaseItemCount(index)">+</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Select a collection date: <span class="text-danger">*</span></label>
                    <input type="date" name="" class="form-control" id="" v-model="selectedDate" @input="dateSelected">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Select a time slot: <span class="text-danger">*</span></label>
                    <select class="form-select" v-model="selectedTimeSlot" v-if="slotsAvailable">
                        <option value="">Select a time slot</option>
                        <option :value="item.slot" v-for="(item, index) in slotTimeOptions" :key="index">{{ item.slot }}</option>
                    </select>
                    <div class="alert alert-warning" v-else>
                        There are no slots available for the selected day. Please choose another date
                    </div>
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Producer of waste SIC code:</label>
                    <input type="text" class="form-control" placeholder="Mobile number" v-model="wasteSICCode">
                </div>

                <div v-show="selectedOption === 2" class="form-group mb-3 mb-xl-4">
                    <label>Anything else we need to know:</label>
                    <textarea class="form-control" id="" cols="30" rows="5" placeholder="Job details" v-model="suggestionsDescription"></textarea>
                </div>
            </div>
        </div>

        <div class="row p-0 p-xl-5 pt-0 pt-xl-0">
            <div class="col"></div>
            <div class="col-auto">
                <router-link class="navbar-brand" to="/">
                    <button class="back_button me-2">
                        Back
                    </button>
                </router-link>
                <button class="submit_button">
                    Submit <i class="bi bi-check-lg"></i>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import { useMyStore } from '@/store';
    import { storage } from '@/firebase';
    import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
    import axios from 'axios';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    export default{
        data() {
            return {
                selectedOption: 2,
                loadsOptions: [],
                hazardousOptions: [],
                addressOptions: [],
                myStore: useMyStore(),
                showAddressSuggestions: false,
                showAddressLines: false,
                searchTerm: '',
                apiKey: 'mQ1gZYmz1U-acU_TBHdpiQ9608',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                hazardusItem: '',
                purchaseOrderPhoto: null,
                photoOfWaste: null,
                isLoading: false,
                selectedDate: '',
                selectedTimeSlot: '',
                slotTimeOptions: [],
                slotsAvailable: true,
                items: [],
                selectedAddressObject: {},
                wasteEntityName: '',
                selectedLoadSize: '',
                selectedLoadOption: '',
                purchaseOrderNumber: '',
                emailAddress: '',
                namePerson: '',
                siteContactName: '',
                siteContactNumber: '',
                wasteSICCode: '',
                wasteType: '',
                ewcCode: '',
                description: '',
                suggestionsDescription: '',
                submittedByName: '',
                purchaseOrderArray: [],
                photosOfWasteArray: [], 
                // apiKey: 'dtoken_hEDzcyiWMr0ANMKW7rnBwSXXTsyziVyQb3WqwoxQqXctq6GYJEFcwD9m7ANJoOMCf4981avNj3Bua4zpFWiYUFFs-zNe4E3ctYzdKbEzbbx4bonGTIPvffGbiToGP6BMsZNwMOP2azOPPwrvlM2KhkJTxXy5UycpF-W6iqZ43J2m_7Vd9XvR0jywQbBwgwO9Mogi7R2RMpY',
            }
        },
        methods: {
            toggleLoadSize() {
                this.selectedLoadOption = this.selectedLoadSize;
                this.selectedLoadSize = '';
            },
            removeLoadSize() {
                this.selectedLoadOption = '';
            },
            async formSubmit() {
                event.preventDefault();
                this.isLoading = true;
                // this.clearAllFields();
                if (
                    this.selectedLoadOption == '' ||
                    this.emailAddress == '' ||
                    this.namePerson == '' ||
                    this.searchTerm == '' ||
                    this.selectedDate == '' ||
                    this.siteContactName == '' ||
                    this.siteContactNumber == '' ||
                    this.selectedTimeSlot == '' ||
                    this.description == ''
                ) {
                    // let user_details = localStorage.getItem('commercials_user_data');
                    // user_details = JSON.parse(user_details);
                    // console.log('user details: -> ', user_details);
                    // console.error('please fill all the fields');
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Error!',
                        text: 'Please fill all the fields',
                        icon: 'error'
                    });
                } else {
                    let user_details = localStorage.getItem('commercials_user_data');
                    user_details = JSON.parse(user_details);
                    // console.log('user details: -> ', user_details);
                    // console.error('please fill all the fields');

                    let temp_AddressObject = this.selectedAddressObject;
                    temp_AddressObject.line_1 = this.addressLine1;
                    temp_AddressObject.line_2 = this.addressLine2;
                    temp_AddressObject.line_3 = this.addressLine3;
                    temp_AddressObject.line_4 = this.addressLine4;
                    const formData = new FormData();

                    if (this.selectedAddressObject?.postcode) {
                        formData.append('address', this.searchTerm + ', ' + this.selectedAddressObject?.postcode );
                    } else {
                        formData.append('address', this.searchTerm );
                    }
                    formData.append('description', this.description);
                    formData.append('bookingDate', this.selectedDate);
                    formData.append('bookingEmail', this.emailAddress);
                    formData.append('contactName', this.namePerson);
                    formData.append('siteContactName', this.siteContactName);
                    formData.append('siteContactNumber', this.siteContactNumber);
                    formData.append('addressDetails', JSON.stringify(temp_AddressObject));
                    this.photosOfWasteArray.forEach((url, index) => {
                        formData.append(`images[${index}]`, url);
                    });
                    this.items.push(this.selectedLoadOption);
                    formData.append('items', JSON.stringify(this.items));
                    formData.append('requestType', this.selectedOption == 2 ? 'booking' : 'quote');
                    formData.append('purchaseOrder', this.purchaseOrderNumber);
                    // formData.append('purchaseOrderDocument', this.purchaseOrderArray);
                    this.purchaseOrderArray.forEach((url, index) => {
                        formData.append(`purchaseOrderDocument[${index}]`, url);
                    });
                    formData.append('wasteEntityName', this.wasteEntityName);
                    formData.append('wasteType', this.wasteType);
                    formData.append('ewcCode', this.ewcCode);
                    formData.append('specialInstructions', this.suggestionsDescription);
                    formData.append('timeSlot', this.selectedTimeSlot);
                    formData.append('submittedByName', this.submittedByName);
                    formData.append('submittedByEmail', user_details.email);

                    console.log('form data: ', formData);

                    // const jsonData = this.formDataToJSON(formData);

                    // const jsonDataStr = JSON.stringify(jsonData, null, 2);
                    // const jsonDataBlob = new Blob([jsonDataStr], { type: 'application/json' });
                    // const downloadLink = document.createElement('a');
                    // downloadLink.href = URL.createObjectURL(jsonDataBlob);
                    // downloadLink.download = 'form_data.json';
                    // downloadLink.click();
                    // https://portal-litta-api.web.app/commercial/users/FQfjVlumvYXbZKE9SG4CDtCRVco2/enquiry
                    // await axios.post(`https://portal-litta-api.web.app/commercial/users/FQfjVlumvYXbZKE9SG4CDtCRVco2/enquiry`, formData).then(response=>{
                    // await axios.post(`https://portal-litta-api.web.app/commercial/users/${user_details.uid}/enquiry`, formData).then(response=>{
                    //     this.isLoading = false;
                    //     console.log('response: ', response);
                    //     this.clearAllFields();
                    //     Swal.fire({
                    //         title: 'Success!',
                    //         text: 'Form Submitted Successfully',
                    //         icon: 'success'
                    //     });
                    // }).then(error=>{
                    //     this.isLoading = false;
                    //     console.error('error', error);
                    // });

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `https://portal-litta-api.web.app/commercial/users/${user_details.uid}/enquiry`,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : formData
                    };

                    axios.request(config).then((response) => {
                        this.isLoading = false;
                        console.log('response: ', response);
                        this.clearAllFields();
                        Swal.fire({
                            title: 'Success!',
                            text: 'Form Submitted Successfully',
                            icon: 'success'
                        });
                    }).catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
                }
            },
            formDataToJSON(formData) {
                const json = {};
                for (const [key, value] of formData.entries()) {
                    if (!json[key]) {
                        json[key] = value;
                    } else {
                        if (!Array.isArray(json[key])) {
                            json[key] = [json[key]];
                        }
                        json[key].push(value);
                    }
                }
                return json;
            },
            clearAllFields(){
                this.selectedOption = 2;
                this.wasteEntityName = '';
                this.selectedLoadSize = '';
                this.selectedLoadOption = '';
                this.purchaseOrderNumber = '';
                this.emailAddress = '';
                this.items = [];
                this.namePerson = '';
                this.purchaseOrderPhoto = null;
                this.searchTerm = '';
                this.showAddressLines = false;
                this.addressLine1 = '';
                this.addressLine2 = '';
                this.addressLine3 = '';
                this.addressLine4 = '';
                this.selectedDate = '';
                this.selectedTimeSlot = '';
                this.photoOfWaste = null;
                this.siteContactName = '';
                this.siteContactNumber = '';
                this.wasteSICCode = '';
                this.wasteType = '';
                this.ewcCode = '';
                this.description = '';
                this.suggestionsDescription = '';
                this.purchaseOrderArray = [];
                this.photosOfWasteArray = [];
            },
            determineFileType(url) {
                // Step 1: Check the file extension from the URL
                const knownImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'];
                const knownPdfExtensions = ['pdf'];
                const knownWordExtensions = ['doc', 'docx'];

                // Check if the URL includes any known extensions
                const isImage = knownImageExtensions.some(ext => url.includes(ext));
                const isPdf = knownPdfExtensions.some(ext => url.includes(ext));
                const isWord = knownWordExtensions.some(ext => url.includes(ext));

                if (isImage) {
                    return 'image';
                } else if (isPdf) {
                    return 'pdf';
                } else if (isWord) {
                    return 'word';
                } else {
                    return 'unknown';
                }
            },
            async dateSelected() {
                this.isLoading = true;

                let user_details = localStorage.getItem('commercials_user_data');
                user_details = JSON.parse(user_details);
                console.log('user details: -> ', user_details);
                let user_id = user_details.uid;

                this.selectedTimeSlot = '';
                this.slotTimeOptions = [];
                let date = this.selectedDate;
                let formattedDate = this.formatDate(date);
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/slots/?date=${formattedDate}`).then(response=>{
                    this.isLoading = false;
                    if (response.data?.data.length > 0) {
                        this.slotTimeOptions = response.data?.data;
                        this.slotsAvailable = true;
                    } else {
                        this.slotsAvailable = false;
                    }
                }).catch(error=>{
                    this.isLoading = false;
                    console.error('error', error);
                });
                console.log(formattedDate);
            },
            formatDate(date) {
                const [year, month, day] = date.split('-');
                return `${day}-${month}-${year}`;
            },
            hazardusSelected() {
                let temp_item = this.hazardusItem;
                temp_item.count = 1;
                let temp_found = false;
                this.items.map((item)=>{
                    if (item.litta_service_linked == temp_item.litta_service_linked) {
                        temp_found = true;
                    }
                });
                if (!temp_found) {
                    this.items.push(temp_item);
                }
                this.hazardusItem = '';
                // console.log('hazardous item selected', temp_item);
            },
            increaseItemCount(index) {
                this.items[index].count += 1; 
            },
            decreseItemCount(index) {
                this.items[index].count -= 1;
                this.items = this.items.filter(item=>{
                    if (item.count > 0) {
                        return true;
                    }
                });
            },
            async uploadFile(event) {
                this.isLoading = true;
                console.log('event: ', event);
                const files = event.target.files;
                if (files.length == 0) {
                    return;
                }
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    const storageReference = storageRef(storage, `uploads/${file.name}`);
                    try {
                        const snapshot = await uploadBytes(storageReference, file);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        this.purchaseOrderArray.push(downloadURL);
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                        console.error('Error uploading file:', error);
                    }
                }
            },
            async uploadFileDrag(event) {
                this.isLoading = true;
                console.log('event: ', event);
                const files = event.dataTransfer?.files;
                if (files.length == 0) {
                    return;
                }
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    const storageReference = storageRef(storage, `uploads/${file.name}`);
                    try {
                        const snapshot = await uploadBytes(storageReference, file);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        this.purchaseOrderArray.push(downloadURL);
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                        console.error('Error uploading file:', error);
                    }
                }
            },
            async uploadFile2(event) {
                this.isLoading = true;
                const files = event.target.files;
                const knownImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'];
                if (files.length == 0) {
                    return;
                }
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (!knownImageExtensions.includes(fileExtension)) {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Only image files are allowed!',
                            icon: 'error'
                        });
                        if (i + 1 === files.length) {
                            this.isLoading = false;
                        }
                        continue;
                    }
                    const storageReference = storageRef(storage, `uploads/${file.name}`);
                    try {
                        const snapshot = await uploadBytes(storageReference, file);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        this.photosOfWasteArray.push(downloadURL);
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                        console.error('Error uploading file:', error);
                    }
                }
            },
            async uploadFile2Drag(event) {
                this.isLoading = true;
                const files = event.dataTransfer.files;
                const knownImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'];
                if (files.length == 0) {
                    return;
                }
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (!knownImageExtensions.includes(fileExtension)) {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Only image files are allowed!',
                            icon: 'error'
                        });
                        if (i + 1 === files.length) {
                            this.isLoading = false;
                        }
                        continue;
                    }
                    const storageReference = storageRef(storage, `uploads/${file.name}`);
                    try {
                        const snapshot = await uploadBytes(storageReference, file);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        this.photosOfWasteArray.push(downloadURL);
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                        console.error('Error uploading file:', error);
                    }
                }
            },
            async fetchAutoFillAddress() {
                this.showAddressLines = false;
                await axios.get(`https://api.getAddress.io/autocomplete/${encodeURIComponent(this.searchTerm.trim())}?api-key=${this.apiKey}&all=true`).then(response=>{
                    console.log('options got: ', response);
                    this.addressOptions = response.data?.suggestions;
                }).catch(error=>{
                    console.error('error occured: ', error);
                });

                this.showAddressSuggestions = true;
            },
            async selectedAdressSuggestion(item){
                console.log('selected id: ', item.id);
                this.selectedAddressObject = item;
                this.searchTerm = item.address;
                this.showAddressLines = true;
                this.hideAdressSuggestions();

                await axios.get(`https://api.getAddress.io/get/${item.id}?api-key=${this.apiKey}`).then(response => {
                    console.log('after selecting the address', response);
                    this.addressLine1 = response.data.line_1;
                    this.addressLine2 = response.data.line_2;
                    this.addressLine3 = response.data.line_3;
                    this.addressLine4 = response.data.line_4;
                }).catch(error => {
                    console.error('error occured: ', error);
                });
            },
            hideAdressSuggestions() {
                setTimeout(() => {
                    this.showAddressSuggestions = false;
                }, 300);
            },
        },
        components: {
            LoadingOverlay,
        },
        async mounted() {
            let user_details = localStorage.getItem('commercials_user_data');
            user_details = JSON.parse(user_details);
            console.log('user details: -> ', user_details);
            let user_id = user_details.uid;
            this.emailAddress = user_details.email;
            if (this.myStore.bookingLoads == null) {
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/loads`).then(response=>{
                    this.myStore.bookingLoads = response.data;
                    this.myStore.bookingUserId = user_id;
                }).catch(error=>{
                    console.error('error occured', error);
                });
            } else if (this.myStore.bookingUserId != user_id) {
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/loads`).then(response=>{
                    this.myStore.bookingLoads = response.data;
                    this.myStore.bookingUserId = user_id;
                }).catch(error=>{
                    console.error('error occured', error);
                });
            }

            if (this.myStore.bookingHazardousItems == null) {
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/haz_items`).then(response=>{
                    this.myStore.bookingHazardousItems = response.data;
                    this.myStore.bookingUserId = user_id;
                }).catch(error=>{
                    console.error('error occured', error);
                });
            } else if (this.myStore.bookingUserId != user_id) {
                await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/haz_items`).then(response=>{
                    this.myStore.bookingHazardousItems = response.data;
                    this.myStore.bookingUserId = user_id;
                }).catch(error=>{
                    console.error('error occured', error);
                });
            }

            let temp_loads = this.myStore.bookingLoads;
            let temp_hazard = this.myStore.bookingHazardousItems;

            this.loadsOptions = this.myStore.bookingLoads.data;
            this.hazardousOptions = this.myStore.bookingHazardousItems.data;

            axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
                this.submittedByName = response.data.data.name;
                this.namePerson = this.submittedByName;
            }).catch(error=>{
                console.error('error occured', error);
            });

            console.log('loads: ', temp_loads);
            console.log('hazardus: ', temp_hazard);
        }
    }
</script>

<style scoped>
    .submit_button{
        background: #06CCCC;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 30px;
        border-radius: 20px;
        border: none;
    }

    .back_button{
        background: #c5c5c5;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 30px;
        border-radius: 20px;
        border: none;
    }

    .photo_upload_div{
        height: 120px;
        align-content: center;
        /* background: #eee; */
        background: #fff;
        cursor: pointer;
        border-radius: 16px;
        border-style: dashed;
        border-width: 2px;
        border-color: rgb(199, 200, 202);
        transition: border 200ms ease 0s, color 300ms ease 0s, width 300ms ease 0s, height 300ms ease 0s;
    }

    .custom_option{
        /* background: #eee; */
        color: #000;
        border: none;
        align-content: center;
        padding: 10px 30px;
        border-color: rgb(6, 204, 204);
        border-radius: 16px;
        border-style: solid;
        border-width: 2px;
        /* border: 1px solid black; */
    }

    .p_relative{
        position: relative;
    }

    .drop_down_options{
        position: absolute;
        width: 100%;
    }

    .drop_down_options li:hover{
        background: #06CCCC;
        color: #fff;
        cursor: pointer;
    }

    .item_count{
        padding: 5px 10px;
        /* border: 1px solid black; */
        margin: 5px;
    }

    .custom_image{
        width: 100%;
    }

    .form_booking{
        background: rgb(246, 249, 250);
        border-radius: 20px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    .form-control{
        /* background: #eeeeee; */
        background: #fff;
        padding: 16px 12px;
        color: rgb(0, 0, 0);
        align-self: baseline;
        align-content: baseline;
        border-radius: 16px;
    }

    .form-select{
        /* background: #eee; */
        background: #fff;
        padding: 16px 12px;
        color: rgb(0, 0, 0);
        align-self: baseline;
        align-content: baseline;
        border-radius: 16px;
    }

    .buttons_custom{
        border: 1px solid black;
        border-radius: 50px;
        overflow: auto;
    }

    .gray-drag{
        color: rgb(199, 200, 202);
    }
    

    li{
        background: #eee;
    }

    @media (min-width: 764px) {
        .custom_image{
            width: 100%;
            height: 145px;
        }

        .form_booking{
            width: 50%;
        }
    }

    @media (max-width: 764px) {
        .custom_option{
            padding: 10px 20px;
        }
    }

    .custom_option.active{
        background: #06CCCC;
        color: #fff;
    }
</style>