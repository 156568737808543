<template>
    <!-- <div class="p-0 p-xl-5 pt-0 pt-xl-0"> -->
    <div v-if="!noDataFound">
        <!-- <p class="main_text ps-1">
            {{ subTitle }}
        </p> -->
        <div class="row mb-3">
            <div class="col">

            </div>

            <div class="col-auto d-flex">
                <div class="row me-2">
                    <div class="col-12 postion_relative">
                        <i class="bi bi-search search_icon"></i>
                        <input type="text" class="form-control search_input" placeholder="Search" v-model="searchText" @input="performFilter">
                    </div>
                </div>
                <!-- <div class="dropdown me-2">
                    <button class="btn btn-custom me-2" type="button" @click="sortOptions = !sortOptions">
                        <b>
                            <i class="bi bi-sort-down"></i>
                            Sort by: {{ sort_order }}
                        </b>
                    </button>

                    <ul class="dropdown-menu" :class="sortOptions == true ? 'show' : ''">
                        <li><a class="dropdown-item" href="javascript:void(0)" @click="sort('ASC')">Sort By: ASC</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" @click="sort('DESC')">Sort By: DESC</a></li>
                    </ul>
                </div> -->
                <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsSort">
                    <i class="bi bi-sort-down"></i> <b>Sort</b>
                </button>

                <div class="modal fade" id="jobsSort" tabindex="-1" aria-labelledby="jobsSortLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsSortLabel">Sort Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>
                                    Select Column
                                </label>
                                <select class="form-select" v-model="selectedSortColumn">
                                    <option :value="option" v-for="(option,index) in sortColumnsCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    Select Order
                                </label>
                                <select class="form-select" v-model="sort_order">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearSort()">Clear Sort</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performSort()" data-bs-dismiss="modal">Apply Sort</button>
                        </div>
                        </div>
                    </div>
                </div>


                <button class="btn btn-custom" type="button" data-bs-toggle="modal" data-bs-target="#jobsFilter">
                    <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                </button>

                <div class="modal fade" id="jobsFilter" tabindex="-1" aria-labelledby="jobsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" style="width: 60vw;max-width: 60vw;">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsFilterLabel">Filter Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="width: 60vw;max-width: 60vw;">
                            <div class="form-group mb-3">
                                <select class="form-select" v-model="selectedStatus">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-xl-5 col-12">
                                    <ul class="list-group filter_time_options">
                                        <li class="list-group-item" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">This Month</li>
                                        <li class="list-group-item" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">Last Month</li>
                                        <li class="list-group-item" :class="checkThisQuarterSelected() ? 'active' : ''" @click="fetchThisQuarterData()">This Quarter</li>
                                        <li class="list-group-item" :class="checkLastQuarterSelected() ? 'active' : ''" @click="lastQuarterData()">Last Quarter</li>
                                        <li class="list-group-item" :class="checkThisYearSelected() ? 'active' : ''" @click="fetchThisYearData()">This Year</li>
                                    </ul>
                                </div>
                                <div class="col-xl-7 col-12">
                                    <div class="form-group mb-3" v-if="default_selection != 'All'">
                                        <label>Select Months</label>
                                        <Calendar
                                            v-model:checkIn="checkIn" 
                                            v-model:checkOut="checkOut"
                                            :alwaysVisible="true"
                                            locale="en"
                                            :bookingColor="bookingColor"
                                            :disabledDaysBeforeDayDate="false"
                                            :placeholder="calendarPlaceholder"
                                        ></Calendar>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group mb-3">
                                <label>
                                    From Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedFrom">
                            </div>

                            <div class="form-group mb-3">
                                <label>
                                    To Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedTo">
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilter()" data-bs-dismiss="modal">Apply Filter</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown ms-3">
                    <a class="btn btn-white btn-custom dropdown-toggle" href="javascript:void(0)" role="button" @click="downloadOptions = !downloadOptions">
                        <i class="bi bi-three-dots-vertical"></i>
                    </a>

                    <ul class="dropdown-menu" :class="downloadOptions == true ? 'show' : ''">
                        <li><a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#metricsFilter" @click="downloadOptions = !downloadOptions">Download CSV</a></li>
                    </ul>
                </div>

                <div class="modal fade" id="metricsFilter" tabindex="-1" aria-labelledby="metricsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" style="width: 60vw;max-width: 60vw;">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="metricsFilterLabel">Select Range</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="width: 60vw;max-width: 60vw;">
                            <div class="row">
                                <div class="col-xl-5 col-12">
                                    <ul class="list-group filter_time_options">
                                        <li class="list-group-item" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">This Month</li>
                                        <li class="list-group-item" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">Last Month</li>
                                        <li class="list-group-item" :class="checkThisQuarterSelected() ? 'active' : ''" @click="fetchThisQuarterData()">This Quarter</li>
                                        <li class="list-group-item" :class="checkLastQuarterSelected() ? 'active' : ''" @click="lastQuarterData()">Last Quarter</li>
                                        <li class="list-group-item" :class="checkThisYearSelected() ? 'active' : ''" @click="fetchThisYearData()">This Year</li>
                                    </ul>
                                </div>
                                <div class="col-xl-7 col-12">
                                    <div class="form-group mb-3" v-if="default_selection != 'All'">
                                        <label>Select Months</label>
                                        <Calendar
                                            v-model:checkIn="checkIn" 
                                            v-model:checkOut="checkOut"
                                            :alwaysVisible="true"
                                            locale="en"
                                            :bookingColor="bookingColor"
                                            :disabledDaysBeforeDayDate="false"
                                            :placeholder="calendarPlaceholder"
                                        ></Calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" @click="clearDateRange()">Reset Range</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilterDataDownloadCSV()" data-bs-dismiss="modal">Download</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-container d-none d-xl-inline">
            <div class="table-responsive tableFixHead">
                <table class="table">
                    <thead>
                        <tr>
                            <template v-for="(field, index) in tableFields" :key="index">
                                <th v-if="field.type != 'hover'" scope="col">{{ field.title }}</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in tableData" :key="index">
                            <template v-for="(field, idx) in row" :key="idx">
                                <td v-if="field.type != 'hover'" >
                                    <template v-if="checkStatus(row) && field.fieldName == 'wasteTransferNote'">
                                        <span>
                                            -
                                        </span>
                                    </template>
                                    <template v-else>
                                        <template v-if="tooltipExist(row,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'currency'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <!-- <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button> -->
                                                -
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <!-- <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button> -->
                                                <p class="hyperlinkCustom">
                                                    Document
                                                </p>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <template v-if="field.fieldName == 'clientPo'">
                                                <span v-if="!field.value || field.value.length <= 15" class="client-po-full">
                                                    {{ field.value }}
                                                </span>
                                                <span v-else class="client-po-truncated" data-bs-toggle="tooltip" data-bs-placement="top" :title="field.value">
                                                    {{ field.value.substring(0, 15) + '...' }}
                                                </span>
                                            </template>
                                            <template v-else>
                                                <span v-if="field.type == 'currency'">
                                                    {{ field.value == null ? ' - ' : ((field.fieldName === 'cost') ? '£ ' : '') + addCommasTwoDecimal(field.value) }}
                                                </span>
                                                <span v-if="field?.type == 'text'">
                                                    {{ field.value == null ? '-' : addCommas(field.value) }}
                                                </span>
                                                <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                    {{ field.value }}
                                                </div>
                                                <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                    <!-- <button class="btn btn-secondary w-100 text-center">
                                                        Document
                                                    </button> -->
                                                    -
                                                </a>
                                                <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                    <!-- <button class="btn btn-primary w-100 text-center">
                                                        Document
                                                    </button> -->
                                                    <p class="hyperlinkCustom">
                                                        Document
                                                    </p>
                                                </a>
                                            </template>
                                        </template>
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <template v-for="(field, index) in tableFields" :key="index">
                                <th v-if="field.type != 'hover'"  scope="col">
                                    <!-- <hr> -->
                                    {{ field.fieldName == 'distance' ? 'Total: ' + formatNumberInteger(sumDistance) : field.fieldName == 'weight' ? 'Total: ' + formatNumberInteger(sumWeight) : field.fieldName == 'volume' ? 'Total: ' + formatNumberInteger(sumVolume) : field.fieldName == 'cost' ? 'Total: £ ' + formatNumber(sumValue) : '' }}
                                </th>
                            </template>
                            
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="table-container d-inline d-xl-none">
            <div class="table-responsive tableFixHead">
                <table class="table">
                    <thead>
                        <tr>
                            <template v-for="(field, index) in tableFields" :key="index">
                                <th v-if="field.type != 'hover' && index < 3" scope="col">{{ field.title }}</th>
                                <th v-if="index==3"></th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in tableData" :key="index" data-bs-toggle="modal" data-bs-target="#tableContents" @click="selectedRowContents = row">
                            <template v-for="(field, idx) in row" :key="idx">
                                <td v-if="field.type != 'hover' && idx < 3" >
                                    <template v-if="checkStatus(row) && field.fieldName == 'wasteTransferNote'">
                                        <span>
                                            -
                                        </span>
                                    </template>
                                    <template v-else>
                                        <template v-if="tooltipExist(row,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'currency'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <!-- <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button> -->
                                                -
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <!-- <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button> -->
                                                <p class="hyperlinkCustom">
                                                    Document
                                                </p>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span v-if="field.type == 'currency'">
                                                {{ field.value == null ? ' - ' : addCommasTwoDecimal(field.value) }}
                                            </span>
                                            <span v-if="field?.type == 'text'">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </span>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                {{ field.value }}
                                            </div>
                                            <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                <!-- <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button> -->
                                                -
                                            </a>
                                            <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                <!-- <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button> -->
                                                <p class="hyperlinkCustom">
                                                    Document
                                                </p>
                                            </a>
                                        </template>
                                    </template>
                                </td>
                                <td v-if="idx==3">
                                    <i class="bi bi-caret-right-fill"></i>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <template v-for="(field, index) in tableFields" :key="index">
                                <th v-if="field.type != 'hover' && index < 3"  scope="col">
                                    <!-- <hr> -->
                                    {{ field.fieldName == 'distance' ? 'Total: ' + formatNumberInteger(sumDistance) : field.fieldName == 'weight' ? 'Total: ' + formatNumberInteger(sumWeight) : field.fieldName == 'volume' ? 'Total: ' + formatNumberInteger(sumVolume) : field.fieldName == 'cost' ? 'Total: £ ' + formatNumber(sumValue) : '' }}
                                </th>
                                <th v-if="index==3"></th>
                            </template>
                            
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>

    <div class="modal fade" id="tableContents" tabindex="-1" aria-labelledby="tableContentsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="tableContentsLabel">More Info</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div v-for="(field,index) in selectedRowContents" :key="index">
                    <p>{{ field.title }}</p>
                    <h4>
                        <span v-if="field.type == 'currency'">
                            {{ field.value == null ? ' - ' : ((field.fieldName === 'cost') ? '£ ' : '') + addCommasTwoDecimal(field.value) }}
                        </span>
                        <span v-if="field?.type == 'text'">
                            {{ field.value == null ? '-' : addCommas(field.value) }}
                        </span>
                        <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                            {{ field.value }}
                        </div>
                        <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                            <button class="btn btn-secondary w-100 text-center">
                                Document
                            </button>
                        </a>
                        <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                            <button class="btn btn-primary w-100 text-center">
                                Document
                            </button>
                        </a>
                    </h4>
                    <hr>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import 'bootstrap/dist/js/bootstrap.bundle.min.js';
    import axios from 'axios';
    import { Calendar } from "vue-calendar-3";
    export default{
        data() {
            return {
                collection: [],
                tableFields: [],
                tableData: [],
                subTitle: '',
                myStore: useMyStore(),
                statusCollection: [],
                selectedStatus: '',
                selectedFrom: '',
                selectedTo: '',
                sumWeight: 0,
                sumVolume: 0,
                sumValue: 0,
                sumDistance: 0,
                sort_order: 'DESC',
                sortOptions: false,
                selectedSortColumn: 'Job Date & Time',
                selectedRowContents: [],
                noDataFound: false,
                downloadOptions: false,
                checkIn: null,
                checkOut: null,
                calendarPlaceholder: { checkIn: "From", checkOut: "To", },
                sortColumnsCollection: [
                    'Job Date & Time',
                    'Distance',
                    'Weight',
                    'Volume',
                    'Cost',
                ],
                searchText: '',
                bookingColor: {
                    admin: "#06CCCC",
                    contract: "#06CCCC",
                }
            }
        },
        components: {
            Calendar,
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            // }).catch(error=>{
            //     console.log('error: ', error);
            // });
            this.myStore.showFooter = false;
            this.fetchData();
            this.fetchThisMonthData();
        },
        methods: {
            clearDateRange() {
                this.fetchThisMonthData();
            },
            fetchThisMonthData() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                this.selectedMonths = [];
                this.selectedMonths.push(currentMonthFullName);
                this.default_selection = currentYear;

                this.checkIn = new Date(currentYear, currentDate.getMonth(), 1).toISOString();
                this.checkOut = new Date(currentYear, currentDate.getMonth() + 1, 0, 23, 59, 59).toISOString();
            },
            lastMonthData() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                this.selectedMonths = [];
                this.selectedMonths.push(lastMonthFullName);
                this.default_selection = lastMonthYear;

                this.checkIn = new Date(lastMonthYear, lastMonthMonth, 1).toISOString();
                this.checkOut = new Date(lastMonthYear, lastMonthMonth + 1, 0, 23, 59, 59).toISOString();
            },
            fetchThisQuarterData() {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3); // Get the current quarter (0, 1, 2, or 3)

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const currentYear = currentDate.getFullYear();
                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            lastQuarterData() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                
                let lastQuarter = Math.floor(currentMonth / 3) - 1; // Get the last quarter (0, 1, 2, or 3)

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            fetchThisYearData() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1).toISOString();
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59).toISOString();

                this.checkIn = yearStart;
                this.checkOut = yearEnd;
            },
            checkThisQuarterSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3);

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkLastQuarterSelected() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                let lastQuarter = Math.floor(currentMonth / 3) - 1;

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire last quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkThisYearSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1);
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire year is selected
                return checkInDate.getTime() === yearStart.getTime() && 
                    checkOutDate.getTime() === yearEnd.getTime();
            },
            checkMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth(); // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },

            checkLastMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth()-1; // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },
            performFilterDataDownloadCSV() {
                // Get check-in and check-out dates
                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Filter the table data based on the date range
                const filteredData = this.tableData.filter(record => {
                    const recordDate = this.parseDateTime(record);
                    console.log(recordDate >= checkInDate && recordDate <= checkOutDate, recordDate, checkInDate, checkOutDate);
                    return recordDate >= checkInDate && recordDate <= checkOutDate;
                });

                const filteredDataWithoutExcludedFields = filteredData.map(record => {
                    const filteredRecord = record.filter(field => {
                        const fieldName = field.fieldName;
                        return !(fieldName === 'hover');
                    });
                    return filteredRecord;
                });

                console.log('filtered data: ', filteredDataWithoutExcludedFields);

                if (filteredDataWithoutExcludedFields.length == 0) {
                    return;
                }

                // Convert filtered data to CSV
                const csvContent = this.convertToCSV(filteredDataWithoutExcludedFields);

                this.downloadFile(csvContent, 'your-metrics.csv');
            },

            convertToCSV(jsonData) {
                // Extract unique titles for the header, removing content within parentheses
                const titles = jsonData[0].map(item => item.title.replace(/\s*\(.*?\)\s*/g, '')).join(',') + '\n';

                // Extract values for the rows, quoting each value
                const rows = jsonData.map(record => 
                    record.map(item => {
                        const value = item.value !== undefined && item.value !== null ? String(item.value) : ''; // Ensure value is a string
                        return `"${value.replace(/"/g, '""')}"`; // Quote and escape double quotes
                    }).join(',')
                ).join('\n');

                return titles + rows;
            },

            downloadFile(content, fileName) {
                const blob = new Blob([content], { type: 'text/csv' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            performSort() {
                if (this.selectedSortColumn == 'Job Date & Time') {
                    this.sort(this.sort_order);
                } else if (this.selectedSortColumn == 'Distance') {
                    this.sortByField('distance');
                } else if (this.selectedSortColumn == 'Weight') {
                    this.sortByField('weight');
                } else if (this.selectedSortColumn == 'Volume') {
                    this.sortByField('volume');
                } else if (this.selectedSortColumn == 'Cost') {
                    this.sortByField('cost');
                }
            },
            sortByField(fieldName) {
                this.tableData.sort((a, b) => {
                    const valueA = this.getFieldValue(a, fieldName);
                    const valueB = this.getFieldValue(b, fieldName);

                    if (valueA === null || valueB === null) {
                        // Handle null values by placing them at the end
                        return valueA === null ? 1 : -1;
                    }

                    if (this.sort_order === 'ASC') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                });
            },

            getFieldValue(record, fieldName) {
                const field = record.find(item => item.fieldName === fieldName);
                return field ? field.value : null;
            },
            sort(val) {
                this.sort_order = val;
                this.sortOptions = false;

                this.tableData.sort((a, b) => {
                    const dateA = this.parseDateTime(a);
                    const dateB = this.parseDateTime(b);

                    if (this.sort_order === 'ASC') {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });

                console.log("Dates of the first 10 records after sorting:");
            },

            parseDateTime(record) {
                const dateField = record.find(item => item.fieldName === 'completionDate');
                const timeField = record.find(item => item.fieldName === 'completionTime');

                if (!dateField || !timeField || !dateField.value || !timeField.value) {
                    // If either date or time value is missing, return a date far in the future
                    return this.sort_order === 'ASC' ? new Date('9999-12-31T23:59:59') : new Date('0000-01-01T00:00:00');
                }

                const dateValue = dateField.value;
                const timeValue = timeField.value;

                const [day, month, year] = dateValue.split('/');
                const [hours, minutes, seconds] = timeValue.split(':');

                return new Date(year, month - 1, day, hours, minutes, seconds);
            },
            async fetchData() {
                if (this.myStore.jobs == null) {
                    let user_details = localStorage.getItem('commercials_user_data');
                    user_details = JSON.parse(user_details);
                    console.log('user details: -> ', user_details);
                    let user_id = user_details.uid;
                    await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}/jobs`)
                    .then(response => {
                        // this.processData(response.data.data);
                        this.myStore.jobs = response;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        this.$emit('triggerFunction');
                    });
                }
                if (this.myStore.jobs){
                    this.processData(this.myStore.jobs.data.data);
                    this.$emit('triggerFunction');
                } else {
                    this.noDataFound = true;
                    this.$emit('triggerFunction');
                }
            },
            processData(data) {
                this.tableFields = data[0].map(item => item);
                this.tableData = data.map(row => row.map(item => item));
                this.performSort();
                data.map((item)=>{
                    item.map((item2)=>{
                        if (item2.fieldName == 'status') {
                            if (!this.statusCollection.includes(item2.value)){
                                this.statusCollection.push(item2.value);
                            }
                        }
                    });
                });
                // this.tableData = this.tableData.map((item) => {
                //     if (item.some(item2 => item2.fieldName === 'jobReference' && item2.value === 'LTC88863')) {
                //         return { ...item, clientPo: 'abc abc abc abc abc abc abc abc abc abc abc abc' };
                //     }
                //     return item;
                // });
                this.sumWeight = 0;
                this.sumDistance = 0;
                this.sumValue = 0;
                this.sumVolume = 0;
                data.map(item => {
                    const tempWeight = item.find(item2 => item2.fieldName === 'weight');
                    const tempVolume = item.find(item2 => item2.fieldName === 'volume');
                    const tempValue = item.find(item2 => item2.fieldName === 'cost');
                    const tempDistance = item.find(item2 => item2.fieldName === 'distance');
                    if (tempWeight.value != null) {
                        this.sumWeight += tempWeight.value; 
                    }
                    if (tempVolume.value != null) {
                        this.sumVolume += tempVolume.value; 
                    }
                    if (tempValue.value != null) {
                        this.sumValue += tempValue.value; 
                        console.log('check sum', tempValue);
                    }
                    if (tempDistance.value != null) {
                        this.sumDistance += tempDistance.value; 
                    }
                });
            },
            clearFilter() {
                this.selectedStatus = '';
                this.selectedFrom = '';
                this.selectedTo = '';
                this.checkIn = null;
                this.checkOut = null;
                this.processData(this.myStore.jobs.data.data);
                this.sumWeight = 0;
                this.sumDistance = 0;
                this.sumValue = 0;
                this.sumVolume = 0;
                this.myStore.jobs.data.data.map(item => {
                    const tempWeight = item.find(item2 => item2.fieldName === 'weight');
                    const tempVolume = item.find(item2 => item2.fieldName === 'volume');
                    const tempValue = item.find(item2 => item2.fieldName === 'cost');
                    const tempDistance = item.find(item2 => item2.fieldName === 'distance');
                    if (tempWeight.value != null) {
                        this.sumWeight += tempWeight.value; 
                    }
                    if (tempVolume.value != null) {
                        this.sumVolume += tempVolume.value; 
                    }
                    if (tempValue.value != null) {
                        this.sumValue += tempValue.value; 
                    }
                    if (tempDistance.value != null) {
                        this.sumDistance += tempDistance.value; 
                    }
                });
            },
            performFilter(){
                let temp_data = [];
                if (this.selectedStatus == '') {
                    temp_data = this.myStore.jobs.data.data;
                } else {
                    this.myStore.jobs.data.data.map((item)=>{
                        item.map((item2)=>{
                            if (item2.fieldName == 'status' && this.selectedStatus == item2.value) {
                                temp_data.push(item);
                            }
                        });
                    });
                }
                if (this.searchText != ''){
                    temp_data = temp_data.filter(item => {
                        for (const field of item) {
                            if (field.value && field.value.toString().toLowerCase().includes(this.searchText.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                if (this.selectedFrom != '' && this.selectedTo != '') {
                    const fromDate = new Date(this.selectedFrom);
                    const toDate = new Date(this.selectedTo);
                    const filteredData = temp_data.filter(item => {
                        const completionDate = item.find(item2 => item2.fieldName === 'completionDate');
                        if (completionDate) {
                            if (completionDate.value != null) {
                                const itemDate = this.parseDate(completionDate.value);
                                return itemDate >= fromDate && itemDate <= toDate;
                            }
                        }
                        return false;
                    });
                    temp_data = filteredData;
                }
                if (this.checkIn && this.checkOut) {
                    const fromDate = new Date(this.checkIn);
                    const toDate = new Date(this.checkOut);
                    const filteredData = temp_data.filter(item => {
                        const completionDate = item.find(item2 => item2.fieldName === 'completionDate');
                        if (completionDate) {
                            if (completionDate.value != null) {
                                const itemDate = this.parseDate(completionDate.value);
                                return itemDate >= fromDate && itemDate <= toDate;
                            }
                        }
                        return false;
                    });
                    temp_data = filteredData;
                }
                this.sumWeight = 0;
                this.sumDistance = 0;
                this.sumValue = 0;
                this.sumVolume = 0;
                temp_data.map(item => {
                    const tempWeight = item.find(item2 => item2.fieldName === 'weight');
                    const tempVolume = item.find(item2 => item2.fieldName === 'volume');
                    const tempValue = item.find(item2 => item2.fieldName === 'cost');
                    const tempDistance = item.find(item2 => item2.fieldName === 'distance');
                    if (tempWeight.value != null) {
                        this.sumWeight += tempWeight.value; 
                    }
                    if (tempVolume.value != null) {
                        this.sumVolume += tempVolume.value; 
                    }
                    if (tempValue.value != null) {
                        this.sumValue += tempValue.value; 
                    }
                    if (tempDistance.value != null) {
                        this.sumDistance += tempDistance.value; 
                    }
                });
                this.tableData = temp_data;
                this.performSort();
                // document.getElementById('jobsFilter').style.display = 'none';
                // const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                // modalBackdrops.forEach(modalBackdrop => {
                //     modalBackdrop.remove();
                // });
                console.log(temp_data);
            },
            parseDate(dateString) {
                const parts = dateString.split('/');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            },
            toHumanReadable(fieldName) {
                return fieldName.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            formatNumber(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');
                let parts = value.split('.');
                let integerPart = parts[0];
                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return integerPart;
            },
            addCommas(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toLocaleString();
                } else {
                    return value;
                }
            },
            addCommasTwoDecimal(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toFixed(2).toLocaleString();
                } else {
                    return value;
                }
            },
            checkStatus(row) {
                let temp_data = row.find(item=>item.fieldName == 'status');
                if (temp_data.value == 'Cancelled') {
                    return true;
                } else {
                    false;
                }
            },
            tooltipExist(row,field){
                let temp_condition = false; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = true;
                    }
                });
                return temp_condition;
            },
            getTooltipValue(row,field) {
                let temp_condition = 'Testing'; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = item.value;
                    }
                });
                return temp_condition;
            },
            clearSort() {
                this.selectedSortColumn = 'Job Date & Time';
                this.sort_order = 'DESC';
                this.performSort();
            },
        }
    }
</script>

<style scoped>
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }
    

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    table { border-collapse: collapse; width: 100%; }
    th, td { background: #fff; padding: 8px 16px; }


    .tableFixHead {
        overflow: auto;
        height: 70vh;
    }

    .tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #eeeeee;
    }

    .tableFixHead tfoot th {
        position: sticky;
        bottom: -5px;
        z-index: 1; /* Ensure the footer is on top of other content */
        background-color: #eeeeee;
    }

    th {
        white-space: nowrap;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    .dynamic_bubble{
        color: #fff;
        padding: 5px 10px;
        text-align:center;
        border-radius: 10px;
    }

    .btn-custom{
        background-color: #fff;
        border: 1px solid #000;
    }

    .cursor_pointer{
        cursor: pointer;
    }

    .search_icon{
        position: absolute;
        top: 8px;
        left: 20px;
    }

    .search_input{
        padding-left: 30px;
    }

    .postion_relative{
        position: relative;
    }

    a{
        text-decoration: none;
    }

    .hyperlinkCustom{
        color: blue;
        text-decoration: underline;
    }

    .dropdown-menu{
        right: 0;
    }

    .btn-white::after{
        display: none;
    }

    .client-po-truncated{
        cursor: pointer;
    }

    .filter_time_options li:hover{
        cursor: pointer;
    }

    .filter_time_options li.active{
        background-color: #06CCCC;
        border: 1px solid #06CCCC;
    }

    #metricsFilter .btn-primary{
        background-color: #06CCCC !important;
        border: 1px solid #06CCCC;
    }

    @media(max-width:764px){
        .table_header p{
            font-size: 16px;
        }
    }
</style>