<template>
    <!-- <div class="p-0 p-xl-5 pt-0 pt-xl-0"> -->
    <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" class="loading-overlay-custom" />
    <div v-if="!noDataFound">
        <div class="row mb-3">
            <div class="col-12 mb-3">
                <p class="p-0 p-xl-5 pt-0 pb-0 pb-xl-0 pt-xl-0 main_text ps-1">
                    {{ subTitle }}
                </p>
            </div>
            <div class="col-12 col-xl mb-3 mb-xl-0 p_relative">
                <div class="buttons_sub_tab">
                    <button class="quick_access_button me-2" :class="tableOpened == 'all' ? 'active' : ''" @click="toggleTable('all')">
                        All Jobs
                    </button>

                    <button class="quick_access_button me-2 d-flex" :class="tableOpened == 'review' ? 'active' : ''" @click="toggleTable('review')">
                        <p class="mb-0">To review </p>
                        <div v-if="reviewsBadgeCount > 0" class=badge_review>{{ reviewsBadgeCount > 99 ? '99+' : reviewsBadgeCount }}</div>
                    </button>

                    <button class="quick_access_button me-2" :class="tableOpened == 'open_query' ? 'active' : ''" @click="toggleTable('open_query')">
                        Open Queries
                    </button>
                </div>
            </div>

            <div class="col-12 col-xl-auto d-block d-xl-flex" v-if="tableOpened != 'open_query'">
                <div class="col-auto p-0 custom_flex text-end">
                    <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsSort">
                        <i class="bi bi-sort-down"></i> <b>Sort</b>
                    </button>

                    <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsFilter">
                        <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                    </button>
                    <div class="p-0 search_input_div">
                        <div class="search_input_div postion_relative">
                            <i class="bi bi-search search_icon"></i>
                            <input type="text" class="form-control search_input" placeholder="Search" v-model="searchText" @input="performFilter">
                        </div>
                    </div>
                    <div class="dropdown ms-3">
                        <a class="btn btn-white btn-custom dropdown-toggle" href="javascript:void(0)" role="button" @click="downloadOptions = !downloadOptions">
                            <i class="bi bi-three-dots-vertical"></i>
                        </a>

                        <ul class="dropdown-menu" :class="downloadOptions == true ? 'show' : ''">
                            <li><a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#metricsFilter" @click="downloadOptions = !downloadOptions">Download CSV</a></li>
                        </ul>
                    </div>
                </div>

                <div class="modal fade" id="metricsFilter" tabindex="-1" aria-labelledby="metricsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" style="width: 60vw;max-width: 60vw;">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="metricsFilterLabel">Select Range</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="width: 60vw;max-width: 60vw;">
                            <div class="row">
                                <div class="col-xl-5 col-12">
                                    <ul class="list-group filter_time_options">
                                        <li class="list-group-item" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">This Month</li>
                                        <li class="list-group-item" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">Last Month</li>
                                        <li class="list-group-item" :class="checkThisQuarterSelected() ? 'active' : ''" @click="fetchThisQuarterData()">This Quarter</li>
                                        <li class="list-group-item" :class="checkLastQuarterSelected() ? 'active' : ''" @click="lastQuarterData()">Last Quarter</li>
                                        <li class="list-group-item" :class="checkThisYearSelected() ? 'active' : ''" @click="fetchThisYearData()">This Year</li>
                                    </ul>
                                </div>
                                <div class="col-xl-7 col-12 partner_calendar">
                                    <div class="form-group mb-3" v-if="default_selection != 'All'">
                                        <label>Select Months</label>
                                        <Calendar
                                            v-model:checkIn="checkIn" 
                                            v-model:checkOut="checkOut"
                                            :alwaysVisible="true"
                                            locale="en"
                                            :bookingColor="bookingColor"
                                            :disabledDaysBeforeDayDate="false"
                                            :placeholder="calendarPlaceholder"
                                            class="custom-calendar"
                                        ></Calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" @click="clearDateRange()">Reset Range</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilterDataDownloadCSV()" data-bs-dismiss="modal">Download</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="jobsSort" tabindex="-1" aria-labelledby="jobsSortLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsSortLabel">Sort Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>
                                    Select Column
                                </label>
                                <select class="form-select" v-model="selectedSortColumn">
                                    <option :value="option" v-for="(option,index) in sortColumnsCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    Select Order
                                </label>
                                <select class="form-select" v-model="sort_order">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearSort()">Clear Sort</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performSort()" data-bs-dismiss="modal">Apply Sort</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="jobsFilter" tabindex="-1" aria-labelledby="jobsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsFilterLabel">Filter Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <select class="form-select" v-model="selectedStatus">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    From Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedFrom">
                            </div>

                            <div class="form-group mb-3">
                                <label>
                                    To Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedTo">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilter()" data-bs-dismiss="modal">Apply Filter</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cars_collections" v-if="tableOpened == 'open_query'">
            <div class="d-none d-xl-block">
                <div class="row mb-3">
                    <div class="col-3">
                        <div class="card heading_card p-3">
                            <div class="d-flex">
                                <h4 class="me-2">In Progress</h4> <h4 class="count_round">{{ tableData.length }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card heading_card p-3">
                            <h4>Accepted </h4>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card heading_card p-3">
                            <h4>Rejected </h4>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card heading_card p-3">
                            <h4>Negotiated </h4>
                        </div>
                    </div>
                </div>

                <div class="row open_queries">
                    <div class="col-3 open_queries_fix_height">
                        <template v-for="(field, index) in tableData" :key="index">
                            <div class="card mb-3" @click="selectedTempJob = field;selectedTempIndex = index">
                                <div class="card-body" data-bs-toggle="modal" data-bs-target="#jobdatacard">
                                    <i class="bi bi-arrows-angle-expand expand_icon"></i>
                                    <b>
                                        <p>Payout (ExVAT)</p>
                                    </b>
                                    <p>
                                        £ {{ field.find(item=>item.fieldName == 'payoutAmount')?.value }}
                                    </p>

                                    <b>
                                        <p>Requested Payout</p>
                                    </b>
                                    <p>
                                        {{ field.find(item=>item.fieldName == 'QueryData')?.value?.requestedAmount != null ? '£ ' + field.find(item=>item.fieldName == 'QueryData')?.value?.requestedAmount : null }}
                                    </p>

                                    <b>
                                        <p>Query Date</p>
                                    </b>
                                    <p>
                                        {{ formatDate(field.find(item=>item.fieldName == 'QueryData')?.value?.timestamp) }}
                                    </p>
                                </div>
                            </div>
                        </template>

                        <!-- <div class="card mb-3 p-3 rounded">
                            <b>
                                <p>Payout (ExVAT)</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Requested Payout</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Date</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Status</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>
                        </div>

                        <div class="card mb-3 p-3 rounded">
                            <b>
                                <p>Payout (ExVAT)</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Requested Payout</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Date</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Status</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>
                        </div>

                        <div class="card mb-3 p-3 rounded">
                            <b>
                                <p>Payout (ExVAT)</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Requested Payout</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Date</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>

                            <b>
                                <p>Query Status</p>
                            </b>
                            <p>
                                £ 74.00
                            </p>
                        </div> -->
                    </div>
                    <div class="col-3 open_queries_fix_height">

                    </div>
                    <div class="col-3 open_queries_fix_height">

                    </div>
                    <div class="col-3 open_queries_fix_height">

                    </div>
                </div>
            </div>

            <div class="d-block d-xl-none">
                <div class="p_relative mb-3">
                    <div class="options_sub_tab" @click="showSubTab = !showSubTab">
                        {{ subTabOpened == 'inprogress' ? 'In Progress' : subTabOpened == 'accepted' ? 'Accepted' : subTabOpened == 'rejected' ? 'Rejected' : subTabOpened == 'negotiated' ? 'Negotiated' : '' }}
                        <i class="bi bi-caret-down-fill"></i>
                    </div>
                    <ul class="list-group sub_tab_options" v-if="showSubTab">
                        <li class="list-group-item" @click="openSubTab('inprogress')">In Progress</li>
                        <li class="list-group-item" @click="openSubTab('accepted')">Accepted</li>
                        <li class="list-group-item" @click="openSubTab('rejected')">Rejected</li>
                        <li class="list-group-item" @click="openSubTab('negotiated')">Negotiated</li>
                    </ul>
                </div>

                <div class="mobile_cards_container">
                    <div class="in_progress_collection" v-if="subTabOpened == 'inprogress'">
                        <template v-for="(field, index) in tableData" :key="index">
                            <div class="card mb-3" @click="selectedTempJob = field;selectedTempIndex = index">
                                <div class="card-body" data-bs-toggle="modal" data-bs-target="#jobdatacard">
                                    <i class="bi bi-arrows-angle-expand expand_icon"></i>
                                    <b>
                                        <p>Payout (ExVAT)</p>
                                    </b>
                                    <p>
                                        £ {{ field.find(item=>item.fieldName == 'payoutAmount')?.value }}
                                    </p>

                                    <b>
                                        <p>Requested Payout</p>
                                    </b>
                                    <p>
                                        {{ field.find(item=>item.fieldName == 'QueryData')?.value?.requestedAmount != null ? '£ ' + field.find(item=>item.fieldName == 'QueryData')?.value?.requestedAmount : null }}
                                    </p>

                                    <b>
                                        <p>Query Date</p>
                                    </b>
                                    <p>
                                        {{ formatDate(field.find(item=>item.fieldName == 'QueryData')?.value?.timestamp) }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="in_progress_collection" v-else>
                        <div class="alert alert-warning">
                            No data found.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-none d-xl-block" v-if="tableOpened == 'all' || tableOpened == 'review'">
            <div class="table-container">
                <div class="table-responsive tableFixHead">
                    <table class="table">
                        <thead>
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover' && !(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry' || field.fieldName == 'QueryData' || field.fieldName == 'forcedPayoutItems') && field != false" scope="col">{{ field.title }}</th>
                                </template>
                                <th scope="col" v-if="tableData.length > 0 && tableOpened == 'review'">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in tableData" :key="index">
                                <template v-for="(field, idx) in row" :key="idx">
                                    <template v-if="!(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry' || field.fieldName == 'QueryData' || field.fieldName == 'forcedPayoutItems') && field != false">
                                        <td v-if="field.type != 'hover'" >
                                            <template v-if="checkStatus(row) && field.fieldName == 'wasteTransferNote'">
                                                <span>
                                                    -
                                                </span>
                                            </template>
                                            <template v-else>
                                                <template v-if="tooltipExist(row,field)">
                                                    <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                        {{ field.value == null ? '-' : addCommas(field.value) }}
                                                    </p>
                                                    <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                        {{ field.value }}
                                                    </div>
                                                    <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                        <button class="btn btn-secondary w-100 text-center">
                                                            Open
                                                        </button>
                                                    </a>
                                                    <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                        <button class="btn btn-primary w-100 text-center">
                                                            Open
                                                        </button>
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <span v-if="field?.type == 'text'">
                                                        {{ field?.fieldName == 'payoutAmount' ? field.value == null ? '-' : '£ ' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : addCommas(field.value) }}
                                                    </span>
                                                    <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                        {{ field.value }}
                                                    </div>
                                                    <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                        <!-- <button class="btn btn-secondary w-100 text-center">
                                                            Open
                                                        </button> -->
                                                        <p class="text-dark" style="cursor:no-drop;">
                                                            view
                                                        </p>
                                                    </a>
                                                    <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                        <!-- <button class="btn btn-primary w-100 text-center">
                                                            Open
                                                        </button> -->
                                                        <p class="custom_hyperlink">
                                                            view
                                                        </p>
                                                    </a>
                                                    <div v-if="field.type == 'array'">
                                                        <img :src="field?.value[0]" v-if="field?.value?.length > 0" class="table_demo_image cursor_pointer" loading="lazy" alt="" @click="toggleImages(field?.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                                    </div>
                                                </template>
                                            </template>
                                        </td>
                                    </template>
                                </template>
                                <td class="" v-if="tableOpened == 'review'">
                                    <div class="d-flex" v-if="checkButtonStatus(row)">
                                        <button class="btn primary_button me-2" @click="triggerAccept(row, index)">Accept</button>

                                        <button class="btn primary_button_second" @click="storeQueryRow(row)" data-bs-toggle="modal" data-bs-target="#queryPopup">Query</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover' && !(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry' || field.fieldName == 'QueryData' || field.fieldName == 'forcedPayoutItems') && field != false">
                                        <!-- <hr> -->
                                        {{ field.fieldName == 'payoutAmount' ? 'Total: ' + formatNumberInteger(sumAmount) : '' }}
                                    </th>
                                </template>
                                <th scope="col" v-if="tableData.length > 0 && tableOpened == 'review'"></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div class="d-block d-xl-none mobile_cards_container" v-if="tableOpened == 'all' || tableOpened == 'review'">
            <template v-for="(row, index) in tableData" :key="index">
                <div class="card mb-3" @click="selectedTempJob = row;selectedTempIndex = index">
                    <div class="card-body" data-bs-toggle="modal" data-bs-target="#jobdatacard">
                        <i class="bi bi-arrows-angle-expand expand_icon"></i>
                        <template v-for="(field, idx) in row" :key="idx">
                            <template v-if="field?.showOnMobileCard && !(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry')">
                                <b>
                                    <p>{{ field.title }}</p>
                                </b>
                                <p v-if="field.type != 'hover'" >
                                    <template v-if="checkStatus(row) && field.fieldName == 'wasteTransferNote'">
                                        <span>
                                            -
                                        </span>
                                    </template>
                                    <template v-else>
                                        <template v-if="tooltipExist(row,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Open
                                                </button>
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Open
                                                </button>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span v-if="field?.type == 'text'">
                                                {{ field?.fieldName == 'payoutAmount' ? field.value == null ? '-' : '£ ' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : addCommas(field.value) }}
                                            </span>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                {{ field.value }}
                                            </div>
                                            <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                <!-- <button class="btn btn-secondary w-100 text-center">
                                                    Open
                                                </button> -->
                                                <p class="text-dark" style="cursor:no-drop;">
                                                    view
                                                </p>
                                            </a>
                                            <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                <!-- <button class="btn btn-primary w-100 text-center">
                                                    Open
                                                </button> -->
                                                <p class="custom_hyperlink">
                                                    view
                                                </p>
                                            </a>
                                            <div v-if="field.type == 'array'">
                                                <img :src="field?.value[0]" v-if="field?.value?.length > 0" class="table_demo_image cursor_pointer close_modal_toggle_images" loading="lazy" alt="" @click="toggleImages(field?.value)">
                                            </div>
                                        </template>
                                    </template>
                                </p>
                            </template>
                        </template>
                    </div>
                    <hr>
                    <div class="d-flex ps-3 pb-3" v-if="checkButtonStatus(row) && tableOpened == 'review'">
                        <button class="btn primary_button me-2" @click.stop="triggerAccept(row, index)">Accept</button>

                        <button class="btn primary_button_second" @click="storeQueryRow(row)" data-bs-toggle="modal" data-bs-target="#queryPopup">Query</button>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="modal fade" id="jobdatacard" tabindex="-1" aria-labelledby="jobdatacardLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="jobdatacardLabel">Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <template v-for="(field, idx) in selectedTempJob" :key="idx">
                        <template v-if="field.section == null && !(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry')">
                            <b>
                                <p>{{ field.title }}</p>
                            </b>
                            <p v-if="field.type != 'hover'" >
                                <template v-if="checkStatus(selectedTempJob) && field.fieldName == 'wasteTransferNote'">
                                    <span>
                                        -
                                    </span>
                                </template>
                                <template v-else>
                                    <template v-if="tooltipExist(selectedTempJob,field)">
                                        <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                            {{ field.value == null ? '-' : addCommas(field.value) }}
                                        </p>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                            {{ field.value }}
                                        </div>
                                        <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                            <button class="btn btn-secondary w-100 text-center">
                                                Open
                                            </button>
                                        </a>
                                        <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                            <button class="btn btn-primary w-100 text-center">
                                                Open
                                            </button>
                                        </a>
                                    </template>
                                    <template v-else>
                                        <span v-if="field?.type == 'text'">
                                            {{ field?.fieldName == 'payoutAmount' ? field.value == null ? '-' : '£ ' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : addCommas(field.value) }}
                                        </span>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                            {{ field.value }}
                                        </div>
                                        <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                            <!-- <button class="btn btn-secondary w-100 text-center">
                                                Open
                                            </button> -->
                                            <p class="text-dark" style="cursor:no-drop;">
                                                view
                                            </p>
                                        </a>
                                        <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                            <!-- <button class="btn btn-primary w-100 text-center">
                                                Open
                                            </button> -->
                                            <p class="custom_hyperlink">
                                                view
                                            </p>
                                        </a>
                                        <div v-if="field.type == 'array'">
                                            <img :src="field?.value[0]" v-if="field?.value?.length > 0" class="table_demo_image cursor_pointer close_modal_toggle_images" loading="lazy" alt="" @click="toggleImages(field?.value)">
                                        </div>
                                    </template>
                                </template>
                            </p>
                        </template>
                    </template>
                    <div v-for="(section,index) in dynamicSections" :key="index" class="card mb-3 p-2">
                        <h2>
                            {{ section }}
                        </h2>
                        <hr>
                        <template v-for="(field, idx) in selectedTempJob" :key="idx">
                            <template v-if="field.section == section && !(field.fieldName == 'firebaseId' || field.fieldName == 'queryBy' || field.fieldName == 'queryStatus' || field.fieldName == 'queryExpiry')">
                                <b>
                                    <p>{{ field.title }}</p>
                                </b>
                                <p v-if="field.type != 'hover'" >
                                    <template v-if="checkStatus(selectedTempJob) && field.fieldName == 'wasteTransferNote'">
                                        <span>
                                            -
                                        </span>
                                    </template>
                                    <template v-else>
                                        <template v-if="tooltipExist(selectedTempJob,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Open
                                                </button>
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Open
                                                </button>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span v-if="field?.type == 'text'">
                                                {{ field?.fieldName == 'payoutAmount' ? field.value == null ? '-' : '£ ' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : addCommas(field.value) }}
                                            </span>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                {{ field.value }}
                                            </div>
                                            <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                <!-- <button class="btn btn-secondary w-100 text-center">
                                                    Open
                                                </button> -->
                                                <p class="text-dark" style="cursor:no-drop;">
                                                    view
                                                </p>
                                            </a>
                                            <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                <!-- <button class="btn btn-primary w-100 text-center">
                                                    Open
                                                </button> -->
                                                <p class="custom_hyperlink">
                                                    view
                                                </p>
                                            </a>
                                            <div v-if="field.type == 'array'">
                                                <img :src="field?.value[0]" v-if="field?.value?.length > 0" class="table_demo_image cursor_pointer close_modal_toggle_images" loading="lazy" alt="" @click="toggleImages(field?.value)">
                                            </div>
                                        </template>
                                    </template>
                                </p>
                            </template>
                        </template>
                    </div>
                    <hr>
                    <div class="d-flex" v-if="checkButtonStatus(selectedTempJob)">
                        <button class="btn primary_button me-2" @click="triggerAccept(selectedTempJob)">Accept</button>

                        <button class="btn primary_button_second" @click="storeQueryRow(selectedTempJob)" data-bs-toggle="modal" data-bs-target="#queryPopup">Query</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
    
    <div class="modal fade" id="imagesPopup" tabindex="-1" aria-labelledby="imagesPopupLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imagesPopupLabel">Images</h5>
                <button type="button" class="btn-close close_modal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <Carousel v-if="showImages">
                    <Slide v-for="(slide,index) in imagesCollection" :key="index">
                        <img class="d-block w-100" :src="slide" alt="slide 1"/>
                        <!-- {{ slide }} -->
                    </Slide>

                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
                <!-- {{ imagesCollection }} -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary close_modal" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="queryPopup" tabindex="-1" aria-labelledby="queryPopupLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered dialog_width_70">
            <div class="modal-content">
            <div class="modal-header">
                <div class="row w-100">
                    <h5 class="modal-title col" id="queryPopupLabel"><strong>Query Payout</strong></h5>
                    <button type="button" class="btn close_modal primary_button text-end col-auto" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
            </div>
            <div class="modal-body">
                <p class="mb-3">
                    Please check all details before submitting a query, especially pictures.
                </p>

                <p class="mb-3">
                    If ypu choose to continue, type your queried payout and state your reasons why along with any evidence.
                </p>

                <hr>

                <div class="form-control">
                    <div class="form-group mb-3 mb-xl-4">
                        <label>Select load size: </label>
                        <select class="form-select" @change="loadSizeSelected" v-model="loadSizeItem" :disabled="tempLoadSizeItem.forced == true ? true : false">
                            <option value="">Select a hazardous item</option>
                            <option :value="item" v-for="(item, index) in hazardousLoads" :key="index">{{ item.service }}</option>
                        </select>
                    </div>

                    <div class="form-group mb-3 mb-xl-4">
                        <label>Select any additional items (if required):</label>
                        <select class="form-select" @change="hazardusSelected" v-model="hazardusItem">
                            <option value="">Select a additional item</option>
                            <option :value="item" v-for="(item, index) in hazardousOptions" :key="index">{{ item.service }}</option>
                        </select>
                    </div>

                    <div class="form-group mb-3 mb-xl-4">
                        <label>Selected loads/items:</label>
                        <input type="text" class="form-control" disabled v-if="items.length == 0 && tempLoadSizeItem == ''" placeholder="No items selected...">
                        <div class="table-responsive" v-else>
                            <table class="table query_table">
                                <thead>
                                    <th>
                                        Service
                                    </th>
                                    <th>
                                        Price Inc Vat
                                    </th>
                                    <th>
                                        Price Ex Vat
                                    </th>
                                    <th>
                                        Count
                                    </th>
                                    <th>
                                        Total
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-if="tempLoadSizeItem != ''">
                                        <td colspan="4">
                                            Loads
                                        </td>
                                    </tr>
                                    <tr v-if="tempLoadSizeItem != ''">
                                        <td>
                                            {{ tempLoadSizeItem.service }}
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ tempLoadSizeItem.price_inc_vat.toFixed(2) }}
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ tempLoadSizeItem.price_ex_vat?.toFixed(2) }}
                                        </td>
                                        <td>
                                            <div class="buttons_custom">
                                                <button type="button" class="btn btn-danger" @click="removeLoadSize">-</button>
                                                <span class="item_count">1</span>
                                            </div>
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ tempLoadSizeItem.price_inc_vat.toFixed(2) }}
                                        </td>
                                    </tr>

                                    <tr v-if="items.length > 0">
                                        <td colspan="4">
                                            Additional Items
                                        </td>
                                    </tr>

                                    <tr v-for="(item,index) in items" :key=index>
                                        <td>
                                            {{ item.service  }}
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ item.price_inc_vat.toFixed(2)  }}
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ item.price_ex_vat?.toFixed(2)  }}
                                        </td>
                                        <td>
                                            <div class="buttons_custom">
                                                <button type="button" class="btn btn-danger" @click="decreseItemCount(index)">-</button>
                                                <span class="item_count">{{ item.count }}</span>
                                                <button type="button" class="btn btn-success" @click="increaseItemCount(index)">+</button>
                                            </div>
                                        </td>
                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ (item.price_inc_vat * item.count).toFixed(2)  }}
                                        </td>
                                    </tr>

                                    <tr v-if="items.length > 0 || tempLoadSizeItem != ''">
                                        <td colspan="4">
                                            Total Payout
                                        </td>

                                        <td>
                                            <i class="bi bi-currency-pound"></i>
                                            {{ queryAmmount?.toFixed(2) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ul class="list-group d-none">
                            <li class="list-group-item" v-if="tempLoadSizeItem != ''">
                                <div class="row">
                                    <div class="col">
                                        {{ tempLoadSizeItem.service }}
                                    </div>
                                    <div class="col-auto buttons_custom">
                                        <button type="button" class="btn btn-danger" @click="removeLoadSize">-</button>
                                        <span class="item_count">1 X</span>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item" v-for="(item,index) in items" :key="index">
                                <div class="row">
                                    <div class="col align-content-center ">
                                        {{ item.service }}
                                    </div>

                                    <div class="col-auto buttons_custom">
                                        <button type="button" class="btn btn-danger" @click="decreseItemCount(index)">-</button>
                                        <span class="item_count">{{ item.count }}</span>
                                        <button type="button" class="btn btn-success" @click="increaseItemCount(index)">+</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="form-group mb-3">
                    <label>Custom Query Amount 
                        <!-- <span class="text-danger" v-if="items.length == 0 && tempLoadSizeItem == ''">*</span> -->
                    </label>
                    <div class="p_relative">
                        <i class="bi bi-currency-pound pound_symbol_input"></i>
                        <input type="text" name="" v-model="customQueryAmmount" class="form-control queryammount_input" id="">
                    </div>
                </div>

                <div class="form-group mb-3" id="scrollToReasonInput">
                    <label>Reason <span class="text-danger">*</span></label>
                    <input type="text" name="" v-model="queryReason" class="form-control" @input="queryReasonEmpty = false" id="">
                    <p class="text-danger" v-if="queryReasonEmpty">Please enter the reason</p>
                </div>

                <div class="form-group mb-3">
                    <label>Evidence</label>
                    <br>
                    <label for="photo_of?_evidence" class="w-100">
                        <div class="btn primary_button">+ Add</div>
                    </label>
                    <input type="file" name="" class="d-none" id="photo_of?_evidence" @change="uploadFile" multiple>
                </div>

                <div class="row mb-3 mb-xl-4" v-if="evidences.length > 0">
                    <div class="col-md-3 p_relative" v-for="(item, index) in evidences" :key="index">
                        <a :href="item" target="_blank" rel="">
                            <img :src="item" class="custom_image mb-2" v-if="determineFileType(item) == 'image'" alt="">
                            <img src="@/assets/file_logo/pdf_logo.png" class="custom_image mb-2" v-else-if="determineFileType(item) == 'pdf'" alt="">
                            <img src="@/assets/file_logo/doc_logo.png" class="custom_image mb-2" v-else-if="determineFileType(item) == 'word'" alt="">
                        </a>
                        <div class="remove_image_query">
                            <button type="button" class="btn close_modal btn-danger text-end col-auto" aria-label="Close" @click="removeEvidence(index)">X</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn primary_button_second close_modal" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn primary_button" @click="saveTheQuery">
                    <i class="bi bi-check-lg"></i> Submit
                </button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import 'bootstrap/dist/js/bootstrap.bundle.min.js';
    import axios from 'axios';
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
    import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
    import 'bootstrap/dist/js/bootstrap.bundle.min.js';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import { storage } from '@/firebase';
    import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
    import { toast } from 'vue3-toastify';
    import 'vue3-toastify/dist/index.css';
    import * as bootstrap from 'bootstrap';
    import { Calendar } from "vue-calendar-3";
    window.bootstrap = bootstrap;
    // import $ from 'jquery';
    export default{
        data() {
            return {
                isLoading: false,
                searchText: '',
                tableOpened: 'all',
                subTabOpened: 'inprogress',
                showSubTab: false,
                requestedByName: '', 
                dynamicSections: [],
                collection: [],
                selectedTempJob: [],
                selectedTempIndex: '',
                tableFields: [],
                tableData: [],
                items: [],
                tempLoadSizeItem: '',
                loadSizeItem: '',
                hazardusItem: '',
                hazardousOptions: [],
                hazardousLoads: [],
                subTitle: '',
                evidences: [],
                myStore: useMyStore(),
                statusCollection: [],
                selectedStatus: '',
                selectedFrom: '',
                selectedTo: '',
                sumAmount: 0,
                noDataFound: false,
                sort_order: 'DESC',
                sortOptions: false,
                queryAmmount: null,
                queryReasonEmpty: false,
                queryReason: '',
                querySelectedRow: '',
                review_query_total: 0,
                sortColumnsCollection: [
                    'Completion Date & Time',
                    'Payout Amount',
                    'Distance',
                ],
                selectedSortColumn: 'Completion Date & Time',
                imagesCollection: [],
                showImages: false,
                breakpoints: {
                    700: {
                        itemsToShow: 1,
                        snapAlign: 'center',
                    },
                    // 1024 and up
                    1024: {
                        itemsToShow: 1,
                        snapAlign: 'start',
                    },
                },
                acceptedIndexes: [],
                reviewsBadgeCount: 0,
                customQueryAmmount: null,
                downloadOptions: false,
                checkIn: null,
                checkOut: null,
                calendarPlaceholder: { checkIn: "From", checkOut: "To", },
                bookingColor: {
                    admin: "#7a37efa9",
                    contract: "#7a37efa9",
                },
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            LoadingOverlay,
            Calendar,
        },
        watch: {
            customQueryAmmount(newValue) {
                if (!this.isValidNumber(newValue)) {
                    this.customQueryAmmount = newValue.slice(0, -1);
                }
            },
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            // }).catch(error=>{
            //     console.log('error: ', error);
            // });
            function showModal(modalId) {
                const modal = document.getElementById(modalId);
                if (modal) {
                    modal.classList.add('show'); // Add 'show' class to display the modal
                    modal.style.display = 'block'; // Make the modal block-level visible
                    document.body.classList.add('modal-open'); // Add class to body to prevent scrolling behind the modal
                }
            }

            // Function to hide modal by removing 'show' class
            function hideModal(modalId) {
                const modal = document.getElementById(modalId);
                const container = modal.querySelector(".container");

                if (modal) {
                    modal.classList.add('hidden'); // Add 'hidden' class to hide the modal
                }

                document.querySelector(".modal").addEventListener("click", function (e) {
                    if (e.target !== modal && e.target !== container) return;     
                    modal.classList.add('hidden'); // Add 'hidden' class to hide the modal
                });
            }

            // Event listener for close buttons with class '.close_modal_toggle_images'
            document.addEventListener('click', function(event) {
                if (event.target.classList.contains('close_modal_toggle_images')) {
                    hideModal('jobdatacard'); // Hide 'jobdatacard' modal
                    showModal('imagesPopup'); // Show 'imagesPopup' modal
                }
            });

            // Event listener for close buttons with class '.close_modal'
            document.addEventListener('click', function(event) {
                if (event.target.classList.contains('close_modal')) {
                    hideModal('imagesPopup'); // Hide 'imagesPopup' modal
                }
            });
            this.myStore.showFooter = false;
            this.fetchData();
            this.fetchHazardusItems();

            // finding sub title
            this.myStore.partner_dashboard.data.data.map((item)=>{
                if (item.type?.includes('tab') && item.tag == 'litta_jobs') {
                    this.subTitle = item.subtitle;
                }
            });

            let user_details = localStorage.getItem('commercials_user_data');
            user_details = JSON.parse(user_details);
            console.log('user details: -> ', user_details);
            let user_id = user_details.uid;

            axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}`).then(response => {
                this.requestedByName = response.data.data.name;
            }).catch(error => {
                console.log('error: ', error);
            });

            this.fetchThisMonthData();
        },
        methods: {
            clearDateRange() {
                this.fetchThisMonthData();
            },
            fetchThisMonthData() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                this.selectedMonths = [];
                this.selectedMonths.push(currentMonthFullName);
                this.default_selection = currentYear;

                this.checkIn = new Date(currentYear, currentDate.getMonth(), 1).toISOString();
                this.checkOut = new Date(currentYear, currentDate.getMonth() + 1, 0, 23, 59, 59).toISOString();
            },
            lastMonthData() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                this.selectedMonths = [];
                this.selectedMonths.push(lastMonthFullName);
                this.default_selection = lastMonthYear;

                this.checkIn = new Date(lastMonthYear, lastMonthMonth, 1).toISOString();
                this.checkOut = new Date(lastMonthYear, lastMonthMonth + 1, 0, 23, 59, 59).toISOString();
            },
            fetchThisQuarterData() {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3); // Get the current quarter (0, 1, 2, or 3)

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const currentYear = currentDate.getFullYear();
                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            lastQuarterData() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                
                let lastQuarter = Math.floor(currentMonth / 3) - 1; // Get the last quarter (0, 1, 2, or 3)

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            fetchThisYearData() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1).toISOString();
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59).toISOString();

                this.checkIn = yearStart;
                this.checkOut = yearEnd;
            },
            checkThisQuarterSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3);

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkLastQuarterSelected() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                let lastQuarter = Math.floor(currentMonth / 3) - 1;

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire last quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkThisYearSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1);
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire year is selected
                return checkInDate.getTime() === yearStart.getTime() && 
                    checkOutDate.getTime() === yearEnd.getTime();
            },
            checkMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth(); // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },

            checkLastMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth()-1; // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },
            performFilterDataDownloadCSV() {
                // Get check-in and check-out dates
                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Filter the table data based on the date range
                let filteredData = this.tableData.filter(record => {
                    const recordDate = this.parseDateTime(record);
                    console.log(recordDate >= checkInDate && recordDate <= checkOutDate, recordDate, checkInDate, checkOutDate);
                    return recordDate >= checkInDate && recordDate <= checkOutDate;
                });

                const filteredDataWithoutExcludedFields = filteredData.map(record => {
                    const filteredRecord = record.filter(field => {
                        const fieldName = field.fieldName;
                        return !(fieldName === 'firebaseId' ||
                                fieldName === 'queryBy' ||
                                fieldName === 'queryStatus' ||
                                fieldName === 'queryExpiry' ||
                                fieldName === 'QueryData' ||
                                fieldName === 'forcedPayoutItems' ||
                                fieldName === 'hover'
                            );
                    });
                    return filteredRecord;
                });

                

                console.log('filtered data: ', filteredDataWithoutExcludedFields);

                if (filteredDataWithoutExcludedFields.length == 0) {
                    return;
                }

                // Convert filtered data to CSV
                const csvContent = this.convertToCSV(filteredDataWithoutExcludedFields);

                this.downloadFile(csvContent, 'your-metrics.csv');
            },

            convertToCSV(jsonData) {
                // Extract unique titles for the header, removing content within parentheses
                const titles = jsonData[0].map(item => item.title.replace(/\s*\(.*?\)\s*/g, '')).join(',') + '\n';

                // Extract values for the rows, quoting each value
                const rows = jsonData.map(record => 
                    record.map(item => {
                        const value = item.value !== undefined && item.value !== null ? String(item.value) : ''; // Ensure value is a string
                        return `"${value.replace(/"/g, '""')}"`; // Quote and escape double quotes
                    }).join(',')
                ).join('\n');

                return titles + rows;
            },

            downloadFile(content, fileName) {
                const blob = new Blob([content], { type: 'text/csv' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            isValidNumber(value) {
                const regex = /^[0-9]*\.?[0-9]*$/;
                return regex.test(value);
            },
            formatDate(isoDate) {
                if (!isoDate) return '';
                const date = new Date(isoDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
            updatequeryAmmount() {
                let temp_count = 0;
                if (this.tempLoadSizeItem != '') {
                    temp_count = temp_count + this.tempLoadSizeItem.price_inc_vat;
                }
                this.items.map((item)=>{
                    temp_count = temp_count + (item.price_inc_vat * item.count);
                });

                if (temp_count == 0) {
                    this.queryAmmount = null;
                } else {
                    this.queryAmmount = temp_count; 
                }
            },
            loadSizeSelected() {
                if (this.tempLoadSizeItem.forced) {
                    // this.tempLoadSizeItem = this.tempLoadSizeItem;
                } else {
                    this.loadSizeItem.forced = false;
                    this.tempLoadSizeItem = this.loadSizeItem;
                    this.loadSizeItem = '';
                    this.updatequeryAmmount();
                }
            },
            openSubTab(value) {
                this.subTabOpened = value;
                this.showSubTab = false;
            },
            storeQueryRow(row) {
                this.querySelectedRow = row;
                this.customQueryAmmount = null;
                this.clearQueryFields();
                console.log(this.querySelectedRow);
                var temp_force_items = this.querySelectedRow.find(item=>item.fieldName == 'forcedPayoutItems');
                if (temp_force_items) {
                    console.log('forced items: ', temp_force_items);
                    temp_force_items = temp_force_items?.value;
                    let temp_items = [];
                    this.hazardousOptions.map(item => {
                        if (temp_force_items.includes(item.record_id)) {
                            let temp_object = {};
                            temp_object = item;
                            temp_object.count = 1;
                            temp_object.forced = true;
                            temp_items.push(temp_object);
                        }
                    });

                    this.hazardousLoads.map(item => {
                        if (temp_force_items.includes(item.record_id)) {
                            let temp_object = {};
                            temp_object = item;
                            temp_object.count = 1;
                            temp_object.forced = true;
                            this.tempLoadSizeItem = temp_object;
                        }
                    });

                    this.items = temp_items;
                    console.log('updated items: ', this.items);
                    this.updatequeryAmmount();
                } else {
                    this.items = [];
                    this.tempLoadSizeItem = '';
                    console.log('No items found');
                }
                // console.log(row);
            },
            removeLoadSize() {
                if (this.tempLoadSizeItem.forced) {
                    // this.tempLoadSizeItem = this.tempLoadSizeItem;
                } else {
                    this.tempLoadSizeItem = '';
                    this.loadSizeItem = '';
                    this.updatequeryAmmount();
                }
            },
            toggleTable(value) {
                this.tableOpened = value;
                this.fetchData();
            },
            updateReviewBadeCount() {
                let temp_collection = this.myStore.partnerJobs?.data.data.filter(item=>{
                    if (item.find(item2=> item2.fieldName == 'queryStatus').value == 'open') {
                        return item;
                    }
                    // if (item.find(item2=>item2.fieldName == 'jobReference')?.value == 'LTA96768') {
                    //     return item;
                    // }
                });
                temp_collection = temp_collection.filter((item,index) => {
                    console.log(index);
                    if (this.acceptedIndexes.includes(item.find(item2 => item2.fieldName == 'jobReference').value)) {
                        // ignore this
                    } else {
                        return item;
                    }
                });
                this.reviewsBadgeCount = temp_collection.length;
            },
            removeEvidence(index) {
                this.evidences.splice(index, 1);
            },
            async saveTheQuery() {
                this.isLoading = true;
                if (this.tempLoadSizeItem != '') {
                    this.items.push(this.tempLoadSizeItem);
                }
                if (this.queryReason == '') {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'Please Fill all the required fields!',
                    //     icon: 'error',
                    // });
                    this.queryReasonEmpty = true;
                    this.isLoading = false;
                    const element = document.getElementById('scrollToReasonInput');
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                    return false;
                } 
                else if ((this.customQueryAmmount == null && this.items.length == 0) ) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Pleae fill query amount or select atleast one item',
                        icon: 'error',
                    });
                    this.isLoading = false;
                    return false;
                } 
                else {
                    let jobId = this.querySelectedRow.find(item=> item.fieldName == 'firebaseId').value;

                    this.acceptedIndexes.push(this.querySelectedRow.find(item => item.fieldName == 'jobReference').value);
                    console.log('accepted Index: ', this.acceptedIndexes);

                    const formData = new FormData();
                    if (this.evidences.length > 0) {
                        this.evidences.forEach((url, index) => {
                            formData.append(`evidence[${index}]`, url);
                        });
                    } else {
                        // formData.append('evidence', JSON.stringify([]));
                        formData.append('evidence', '[]');
                    }

                    if (this.items.length > 0) {
                        this.items.forEach((item, index) => {
                            formData.append(`payoutItems[${index}]`, JSON.stringify(item));
                        });
                    } else {
                        // formData.append('payoutItems', JSON.stringify([]));
                        formData.append('payoutItems' + '[]', []);
                    }

                    formData.append('jobId', jobId);
                    formData.append('reason', this.queryReason);
                    formData.append('overrideAmount', parseFloat(this.customQueryAmmount).toFixed(2));
                    formData.append('requestedBy', this.requestedByName);

                    // const jsonData = this.formDataToJSON(formData);

                    // const jsonDataStr = JSON.stringify(jsonData, null, 2);
                    // const jsonDataBlob = new Blob([jsonDataStr], { type: 'application/json' });
                    // const downloadLink = document.createElement('a');
                    // downloadLink.href = URL.createObjectURL(jsonDataBlob);
                    // downloadLink.download = 'form_data.json';
                    // downloadLink.click();

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `https://portal-litta-api.web.app/partners/users/query`,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : {
                            evidence: this.evidences,
                            payoutItems:  this.items, 
                            jobId: jobId,
                            reason: this.queryReason,
                            overrideAmount: this.queryAmmount != null ? Number(parseFloat(this.customQueryAmmount).toFixed(2)) : null,
                            requestedBy: this.requestedByName,
                        }
                    };

                    axios.request(config).then((response) => {
                        this.isLoading = false;
                        console.log('response: ', response);
                        this.clearQueryFields();
                        // this.myStore.partnerJobs = null;
                        this.fetchData();
                        // Swal.fire({
                        //     title: 'Success!',
                        //     text: 'Form Submitted Successfully',
                        //     icon: 'success'
                        // });
                        var myModalEl = document.getElementById('queryPopup');
                        var modal = bootstrap.Modal.getInstance(myModalEl);
                        modal.hide();
                        var backdrop = document.querySelector('.modal-backdrop');
                        if (backdrop) {
                            backdrop.parentNode.removeChild(backdrop);
                        }
                        toast.success('Success');
                    }).catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Sorry Something went wrong, please try again later',
                            icon: 'error'
                        });
                    });
                }
            },
            formDataToJSON(formData) {
                const json = {};
                for (const [key, value] of formData.entries()) {
                    if (!json[key]) {
                        json[key] = value;
                    } else {
                        if (!Array.isArray(json[key])) {
                            json[key] = [json[key]];
                        }
                        json[key].push(value);
                    }
                }
                return json;
            },
            clearQueryFields() {
                this.items = [];
                this.queryAmmount = null;
                this.queryReason = '';
                this.evidences = [];
                this.queryReasonEmpty = false;
            },
            hazardusSelected() {
                let temp_item = this.hazardusItem;
                temp_item.count = 1;
                let temp_found = false;
                this.items.map((item)=>{
                    if (item.service == temp_item.service) {
                        temp_found = true;
                    }
                });
                if (!temp_found) {
                    this.items.push(temp_item);
                }
                this.hazardusItem = '';
                // console.log('hazardous item selected', temp_item);
                this.updatequeryAmmount();
            },
            async fetchHazardusItems() {
                if (this.myStore.partnerQueryItemsLoads == null) {
                    await axios.get(`https://portal-litta-api.web.app/partners/payout_items`).then(response=>{
                        this.myStore.partnerQueryItemsLoads = response.data;
                    }).catch(error=>{
                        console.error('error occured', error);
                    });
                }

                this.hazardousOptions = this.myStore.partnerQueryItemsLoads.data.items.sort((a, b) => {
                    if (a.service < b.service) {
                        return -1;
                    }
                    if (a.service > b.service) {
                        return 1;
                    }
                    return 0;
                });
                this.hazardousLoads = this.myStore.partnerQueryItemsLoads.data.loads.sort((a, b) => {
                    if (a.service < b.service) {
                        return -1;
                    }
                    if (a.service > b.service) {
                        return 1;
                    }
                    return 0;
                });
                console.log('hazardous items: ', this.myStore.partnerQueryItemsLoads);
            },
            increaseItemCount(index) {
                this.items[index].count += 1; 
                this.updatequeryAmmount();
            },
            decreseItemCount(index) {
                if (this.items[index].forced) {
                    if(this.items[index].count > 1){
                        this.items[index].count -= 1;
                        this.items = this.items.filter(item=>{
                            if (item.count > 0) {
                                return true;
                            }
                        });
                        this.updatequeryAmmount();
                    }
                } else {
                    this.items[index].count -= 1;
                    this.items = this.items.filter(item=>{
                        if (item.count > 0) {
                            return true;
                        }
                    });
                    this.updatequeryAmmount();
                }
            },
            checkButtonStatus(row) {
                // return true;
                let status = row.find(item=>item.fieldName == 'queryStatus')?.value;
                let expireDate = row.find(item => item.fieldName == 'queryExpiry')?.value;
                // console.log('status', status, 'expiry date: ', expireDate);
                // if (row.find(item=>item.fieldName == 'jobReference')?.value == 'LTA96768') {
                //     return true;
                // }
                if (status?.toLowerCase() !== 'open') {
                    return false;
                }

                if (expireDate == null) {
                    return false;
                }

                let expireDateObj = new Date(expireDate);
                let currentDate = new Date();

                if (currentDate > expireDateObj) {
                    return false;
                }

                return true;
            },
            async triggerAccept(row,index){
                this.isLoading = true;

                let jobId = row.find(item=> item.fieldName == 'firebaseId').value;

                const data = {
                    jobId: jobId,
                    requestedBy: this.myStore.partnerCompanyName,
                    timestamp: new Date().toISOString()
                };

                console.log(index);
                this.acceptedIndexes.push(row.find(item => item.fieldName == 'jobReference').value);
                console.log('accepted indexs:', this.acceptedIndexes);

                axios.post('https://portal-litta-api.web.app/partners/users/query/accept', data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    console.log('Response:', response.data);
                    // this.myStore.partnerJobs = null;
                    this.fetchData();
                    this.isLoading = false;
                    // Swal.fire({
                    //     title: 'Success!',
                    //     text: 'Query successfully created!',
                    //     icon: 'success'
                    // });
                    toast.success('Success');
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.isLoading = false;
                });
            },
            async uploadFile(event) {
                this.isLoading = true;
                console.log('event: ', event);
                const files = event.target.files;
                if (files.length == 0) {
                    return;
                }
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    const storageReference = storageRef(storage, `partner_evidence/${file.name}`);
                    try {
                        const snapshot = await uploadBytes(storageReference, file);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        this.evidences.push(downloadURL);
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        if (i + 1 == files.length) {
                            this.isLoading = false;
                        }
                        console.error('Error uploading file:', error);
                    }
                }
            },
            determineFileType(url) {
                // Step 1: Check the file extension from the URL
                const knownImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'];
                const knownPdfExtensions = ['pdf'];
                const knownWordExtensions = ['doc', 'docx'];

                // Check if the URL includes any known extensions
                const isImage = knownImageExtensions.some(ext => url.includes(ext));
                const isPdf = knownPdfExtensions.some(ext => url.includes(ext));
                const isWord = knownWordExtensions.some(ext => url.includes(ext));

                if (isImage) {
                    return 'image';
                } else if (isPdf) {
                    return 'pdf';
                } else if (isWord) {
                    return 'word';
                } else {
                    return 'unknown';
                }
            },
            clearSort(){
                this.selectedSortColumn = 'Completion Date & Time';
                this.sort_order = 'DESC';
                this.performSort();
            },
            toggleImages(val) {
                this.imagesCollection = [];
                this.showImages = false;
                this.imagesCollection = val;
                this.showImages = true;
                this.$forceUpdate();
                console.log(val);
            },
            performSort() {
                if (this.selectedSortColumn == 'Completion Date & Time') {
                    this.sort(this.sort_order);
                } else if (this.selectedSortColumn == 'Payout Amount') {
                    this.sortByField('payoutAmount');
                } else if (this.selectedSortColumn == 'Distance') {
                    this.sortByField('distance');
                }
            },
            sortByField(fieldName) {
                this.tableData.sort((a, b) => {
                    const valueA = this.getFieldValue(a, fieldName);
                    const valueB = this.getFieldValue(b, fieldName);

                    if (valueA === null || valueB === null) {
                        // Handle null values by placing them at the end
                        return valueA === null ? 1 : -1;
                    }

                    if (this.sort_order === 'ASC') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                });
            },
            getFieldValue(record, fieldName) {
                const field = record.find(item => item.fieldName === fieldName);
                return field ? field.value : null;
            },
            sort(val) {
                this.sort_order = val;
                this.sortOptions = false;

                this.tableData.sort((a, b) => {
                    const dateA = this.parseDateTime(a);
                    const dateB = this.parseDateTime(b);

                    if (this.sort_order === 'ASC') {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });

                console.log("Dates of the first 10 records after sorting:");
            },

            parseDateTime(record) {
                const dateField = record.find(item => item.fieldName === 'completionDate');
                const timeField = record.find(item => item.fieldName === 'completionTime');

                if (!dateField || !timeField || !dateField.value || !timeField.value) {
                    // If either date or time value is missing, return a date far in the future
                    return this.sort_order === 'ASC' ? new Date('9999-12-31T23:59:59') : new Date('0000-01-01T00:00:00');
                }

                const dateValue = dateField.value;
                const timeValue = timeField.value;

                const [day, month, year] = dateValue.split('/');
                const [hours, minutes, seconds] = timeValue.split(':');

                return new Date(year, month - 1, day, hours, minutes, seconds);
            },
            async fetchData() {
                if (this.myStore.partnerJobs == null) {
                    let user_details = localStorage.getItem('commercials_user_data');
                    user_details = JSON.parse(user_details);
                    console.log('user details: -> ', user_details);
                    let user_id = user_details.uid;
                    // await axios.get(`https://portal-litta-api.web.app/partners/users/bjzz5Awf7IUEv8jdVuwu5Z6NRMI2/jobs`)
                    await axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}/jobs`)
                    .then(response => {
                        console.log('fetched');
                        this.myStore.partnerJobs = response;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });

                    this.acceptedIndexes = [];
                }
                if (this.myStore.partnerJobs) {
                    if (this.tableOpened == 'all') {
                        console.log('data jobs', this.myStore.partnerJobs);
                        this.processData(this.myStore.partnerJobs?.data.data);
                        this.performFilter();
                        this.performSort();
                        this.updateReviewBadeCount();
                    } else if (this.tableOpened == 'review') {
                        console.log('here');
                        let temp_collection = this.myStore.partnerJobs?.data.data.filter(item=>{
                            if (item.find(item2=> item2.fieldName == 'queryStatus').value == 'open') {
                                return item;
                            }
                            // if (item.find(item2=>item2.fieldName == 'jobReference')?.value == 'LTA96768') {
                            //     return item;
                            // }
                        });

                        temp_collection = temp_collection.filter((item,index) => {
                            console.log(index);
                            if (this.acceptedIndexes.includes(item.find(item2 => item2.fieldName == 'jobReference').value)) {
                                // ignore this
                            } else {
                                return item;
                            }
                        });

                        console.log('collection: ', temp_collection);

                        this.processData(temp_collection);
                        this.performFilter();
                        this.performSort();
                        this.updateReviewBadeCount();
                    } else {
                        let temp_collection = this.myStore.partnerJobs?.data.data.filter(item=>{
                            if (item.find(item2=> item2.fieldName == 'queryStatus').value == 'inProgress') {
                                return item;
                            }
                            // if (item.find(item2=>item2.fieldName == 'jobReference')?.value == 'LTA96768') {
                            //     return item;
                            // }
                        });
                        console.log('data jobs', temp_collection);
                        this.processData(temp_collection);
                        this.performFilter();
                        this.performSort();
                        this.updateReviewBadeCount();
                    }
                } else {
                    this.noDataFound = true;
                }
                this.$emit('triggerFunction');
            },
            processData(data) {
                this.tableFields = data[0]?.map(item => item);
                this.tableFields?.map((item)=>{
                    if (item.section != null) {
                        if (this.dynamicSections.includes(item.section)) {
                            console.log('ignoring', item.section);
                        } else {
                            this.dynamicSections.push(item.section);
                        }
                    }
                });
                this.tableData = data.map(row => row.map(item => item));
                this.sort(this.sort_order);
                data.map((item)=>{
                    item.map((item2)=>{
                        if (item2.fieldName == 'status' && item2.title == 'Status') {
                            if (!this.statusCollection.includes(item2.value)){
                                this.statusCollection.push(item2.value);
                            }
                        }
                    });
                });
                this.sumAmount = 0;
                data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
            },
            clearFilter() {
                this.selectedStatus = '';
                this.selectedFrom = '';
                this.selectedTo = '';
                this.processData(this.myStore.partnerJobs.data.data);
                this.sumAmount = 0;
                this.myStore.partnerJobs.data.data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
                this.performSort();
            },
            performFilter(){
                let temp_data = [];
                if (this.selectedStatus == '') {
                    if (this.tableOpened == 'all') {
                        temp_data = this.myStore.partnerJobs.data.data;
                    } else if (this.tableOpened == 'review') {
                        temp_data = this.myStore.partnerJobs?.data.data.filter(item=>{
                            if (item.find(item2=> item2.fieldName == 'queryStatus').value == 'open') {
                                return item;
                            }
                            // if (item.find(item2=>item2.fieldName == 'jobReference')?.value == 'LTA96768') {
                            //     return item;
                            // }
                        });
                        temp_data = temp_data.filter((item) => {
                            if (this.acceptedIndexes.includes(item.find(item2 => item2.fieldName == 'jobReference').value)) {
                                // ignore this
                            } else {
                                return item;
                            }
                        });
                    } else {
                        temp_data = this.myStore.partnerJobs.data.data;
                    }
                } else {
                    if (this.tableOpened == 'all') {
                        this.myStore.partnerJobs.data.data.map((item)=>{
                            item.map((item2)=>{
                                if (item2.fieldName == 'status' && this.selectedStatus == item2.value) {
                                    temp_data.push(item);
                                }
                            });
                        });
                    } else if (this.tableOpened == 'review') {
                        let temp_collection = this.myStore.partnerJobs?.data.data.filter(item=>{
                            if (item.find(item2=> item2.fieldName == 'queryStatus').value == 'open') {
                                return item;
                            }
                        });
                        temp_collection = temp_collection.filter((item) => {
                            if (this.acceptedIndexes.includes(item.find(item2 => item2.fieldName == 'jobReference').value)) {
                                // ignore this
                            } else {
                                return item;
                            }
                        });
                        temp_collection.map((item)=>{
                            item.map((item2)=>{
                                if (item2.fieldName == 'status' && this.selectedStatus == item2.value) {
                                    temp_data.push(item);
                                }
                            });
                        });
                    } else {
                        this.myStore.partnerJobs.data.data.map((item)=>{
                            item.map((item2)=>{
                                if (item2.fieldName == 'status' && this.selectedStatus == item2.value) {
                                    temp_data.push(item);
                                }
                            });
                        });
                    }
                }

                if (this.searchText != ''){
                    temp_data = temp_data.filter(item => {
                        for (const field of item) {
                            if (field.value && field.value.toString().toLowerCase().includes(this.searchText.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                if (this.selectedFrom != '' && this.selectedTo != '') {
                    const fromDate = new Date(this.selectedFrom);
                    const toDate = new Date(this.selectedTo);
                    const filteredData = temp_data.filter(item => {
                        const completionDate = item.find(item2 => item2.fieldName === 'completionDate');
                        if (completionDate) {
                            if (completionDate.value != null) {
                                const itemDate = this.parseDate(completionDate.value);
                                return itemDate >= fromDate && itemDate <= toDate;
                            }
                        }
                        return false;
                    });
                    temp_data = filteredData;
                }
                this.sumAmount = 0;
                temp_data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
                this.tableData = temp_data;
                // document.getElementById('jobsFilter').style.display = 'none';
                // const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                // modalBackdrops.forEach(modalBackdrop => {
                //     modalBackdrop.remove();
                // });
                // console.log(temp_data);
                this.performSort();
            },
            parseDate(dateString) {
                const parts = dateString.split('/');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            },
            toHumanReadable(fieldName) {
                return fieldName.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            formatNumber(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';
                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);
                if(val > 0) {
                    return '£ ' + integerPart + (decimalPart ? '.' + decimalPart : '');
                }
                return integerPart;
            },
            addCommas(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toLocaleString();
                } else {
                    return value;
                }
            },
            checkStatus(row) {
                let temp_data = row.find(item=>item.fieldName == 'status');
                if (temp_data.value == 'Cancelled') {
                    return true;
                } else {
                    false;
                }
            },
            tooltipExist(row,field){
                let temp_condition = false; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = true;
                    }
                });
                return temp_condition;
            },
            getTooltipValue(row,field) {
                let temp_condition = 'Testing'; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = item.value;
                    }
                });
                return temp_condition;
            },
        }
    }
</script>

<style scoped>
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }
    

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    table { border-collapse: collapse; width: 100%; }
    th, td { background: #fff; padding: 8px 16px; }


    .tableFixHead {
        overflow: auto;
        height: 60vh;
    }

    .tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #eeeeee;
    }

    .tableFixHead tfoot th {
        position: sticky;
        bottom: -5px;
        z-index: 1; /* Ensure the footer is on top of other content */
        background-color: #eeeeee;
    }

    th {
        white-space: nowrap;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    .dynamic_bubble{
        color: #fff;
        padding: 5px 10px;
        text-align:center;
        border-radius: 10px;
    }

    .btn-custom{
        background-color: #fff;
        border: 1px solid #000;
    }

    .cursor_pointer{
        cursor: pointer;
    }

    .table_demo_image{
        width: 50px;
    }

    a{
        text-decoration: none;
    }
    .custom_hyperlink{
        color: blue;
        text-decoration: underline;
    }

    .search_icon{
        position: absolute;
        top: 8px;
        left: 10px;
    }

    .postion_relative{
        position: relative;
    }

    .search_input{
        padding-left: 30px;
    }

    .search_input_div{
        width: 45vw;
    }

    .custom_flex{
        display: inline-flex;
        width: 100%;
    }

    .mobile_cards_container{
        overflow: auto;
        height: 70vh;
    }

    .mobile_cards_container .card-body{
        position: relative;
    }

    .mobile_cards_container .expand_icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .open_queries .card-body{
        position: relative;
    }

    .open_queries .expand_icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    #jobdatacard .modal-body{
        background: #f5f5f5;
    }

    .primary_button{
        background: #7B37EF;
        color: #fff;
        border: 1px solid #7B37EF;
    }

    .primary_button_second{
        border: 1px solid #7B37EF;
        color: #7B37EF;
        background: #fff;
    }

    .item_count{
        padding: 5px 10px;
        /* border: 1px solid black; */
        margin: 5px;
    }

    .queryammount_input{
        padding-left: 20px;
    }

    .p_relative{
        position: relative;
    }

    .pound_symbol_input{
        position: absolute;
        top: 8px;
        left: 5px;
    }
    
    .custom_image{
        width: 100%;
    }

    .quick_access_button{
        background: rgb(235, 235, 235);
        border: none;
        padding: 5px 20px;
        border-radius: 5px;
    }

    .quick_access_button.active{
        border: 2px solid #7B37EF;
    }

    .heading_card{
        border-radius: 20px;
        border-top: 5px solid #7B37EF;
    }

    .open_queries_fix_height{
        position: relative;
        height: 50vh;
        overflow-y: scroll;
    }

    .open_queries_fix_height .card{
        border: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .count_round{
        background: #7B37EF;
        padding: 2px 10px;
        border-radius: 100%;
        color: #fff;
    }

    .buttons_sub_tab{
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .buttons_sub_tab::-webkit-scrollbar {
        display: none;
    }

    .options_sub_tab{
        background: #7B37EF;
        border: 1px solid #7B37EF;
        text-align: center;
        border-radius: 10px;
        padding: 5px;
        color: #fff;
    }

    .sub_tab_options{
        width: 100%;
        position: absolute;
        z-index: 999;
    }

    .buttons_custom{
        border: 1px solid black;
        border-radius: 50px;
        overflow: auto;
        padding: 0;
        width: fit-content;
    }

    .badge_review{
        background-color: #7B37EF;
        color: #fff;
        width: 35px;
        height: 35px;
        font-size: 12px;
        border-radius: 100%;
        align-content: center;
        position: absolute;
        top: -20px;
        left: 216px;
    }

    .remove_image_query button{
        position: absolute;
        top: 10px;
        right: 20px;
    }

    .dropdown-menu{
        right: 0;
    }

    .btn-white::after{
        display: none;
    }

    .client-po-truncated{
        cursor: pointer;
    }

    .filter_time_options li.active{
        background-color: #7B37EF;
        border: 1px solid #7B37EF;
    }

    #metricsFilter .btn-primary{
        background-color: #7B37EF !important;
        border: 1px solid #7B37EF;
    }

    @media (max-width: 800px) {
        .buttons_custom {
            display: inline-grid;
        }
    }

    @media(min-width: 1200px) {
        .dialog_width_70{
            max-width: 70vw;
        }
    }

    @media (min-width: 764px) {
        .custom_image{
            width: 100%;
            height: 145px;
        }
    }

    @media(min-width: 1200px) {
        .search_input_div{
            width: 20vw;
        }
    }

    b p{
        margin-bottom: 0;
    }

    @media(max-width:764px){
        .table_header p{
            font-size: 16px;
        }

        .query_table th{
            background: #fff;
            padding: 8px 0px;
            font-size: 12px;
        }

        .query_table td{
            background: #fff;
            padding: 8px 0px;
            font-size: 12px;
        }
    }
</style>

<style>
.loading-overlay-custom {
  z-index: 2000 !important;
}
.custom-calendar .selected-range {
  background-color: #7B37EF; /* Replace with your desired color */
}
</style>