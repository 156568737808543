<template>
    <div class="row p-0 p-xl-5 pt-0 pt-xl-0" v-if="!noDataFound">
        <div class="col-12 col-xl-12">
            <!-- <p class="main_text ps-1">
                Here’s is a list of the jobs that <span>you trusted us</span> with. Or some dummy copy to not make this page look empty.
            </p> -->
            <!-- <p class="main_text ps-1">
                {{ subTitle }}
            </p> -->
            <p class="subtitle_text">
                {{ subTitle }}
            </p>
            <div class="row mb-3 mt-3">
                <div class="col-12 col-xl d-flex mb-3 mb-xl-0">
                    <button class="quick_access_button me-2" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">
                        This Month
                    </button>

                    <button class="quick_access_button me-2" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">
                        Last Month
                    </button>
                </div>
                <div class="col-12 col-xl-auto d-flex">
                    <!-- <div class="dropdown">
                        <a class="btn btn-white text-dark dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ default_selection }}
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="javascript:void(0)" @click="yearSelected('All')">All</a></li>
                            <li v-for="(value,index) in yearCollection" :key="index">
                                <a class="dropdown-item" href="javascript:void(0)" @click="yearSelected(value)">{{ value }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown ms-2" v-if="default_selection != 'All'">
                        <a class="btn btn-white text-dark dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ month_selected }}
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li v-for="(value,index) in monthCollection" :key="index">
                                <a class="dropdown-item" href="javascript:void(0)" @click="monthSelected(value)">{{ value }}</a>
                            </li>
                        </ul>
                    </div> -->

                    <button class="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#metricsFilter">
                        <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                    </button>

                    <div class="dropdown ms-3">
                        <a class="btn btn-white dropdown-toggle" href="javascript:void(0)" role="button" @click="downloadOptions = !downloadOptions">
                            <i class="bi bi-three-dots-vertical"></i>
                        </a>

                        <ul class="dropdown-menu" :class="downloadOptions == true ? 'show' : ''">
                            <li><a class="dropdown-item" href="javascript:void(0)" @click="downloadCSV">Download CSV</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0)" @click="generatePDF">Download PDF</a></li>
                        </ul>
                    </div>

                    <div class="modal fade" id="metricsFilter" tabindex="-1" aria-labelledby="metricsFilterLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="metricsFilterLabel">Filter Options</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group mb-3">
                                    <label>Select Year</label>
                                    <select class="form-select" v-model="default_selection" @change="yearSelected(default_selection)">
                                        <option value="All">All</option>
                                        <option :value="option" v-for="(option,index) in yearCollection" :key="index">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <hr>
                                <div class="form-group mb-3" v-if="default_selection != 'All'">
                                    <label>Select Months</label>
                                    <VueMultiselect
                                        v-model="selectedMonths"
                                        :options="monthCollection"
                                        placeholder="Select months"
                                        :multiple="true"
                                        :close-on-select="true"
                                    >
                                    </VueMultiselect>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" @click="performFilter()">Apply Filter</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-12 pt-0" id="cardsCollectionContainer">
            <div class="row" v-if="allCardDetails.length > 0">
                <template v-for="(value, key) in allCardDetails" :key="key">
                    <template v-if="(value.fieldName == 'noOfPoorJobs' || value.fieldName == 'noOfGoodJobs' || value.fieldName == 'noOfExcelentJobs' || value.fieldName == 'onTimePercentage' || value.fieldName == 'onTime')"></template>
                    <div v-else class="col-12 col-xl-3 mb-3">
                        <div class="card custom_card p-3">
                            <div class="card-body">
                                <p class="card_title">
                                {{ getLabel(value.title) }}
                                </p>
                                <h2 class="card_content">
                                    <span v-if="value.title.includes('£')">
                                        {{ getLabel3(value.title) }}
                                    </span>
                                    <NumberAnimation
                                        :from="0"
                                        :to="value.value"
                                        :format="value.title.includes('£') ? formatNumberPay : formatNumberInteger"
                                        :duration="1"
                                        easing="linear"
                                    />
                                    {{ value.title.includes('£') ? '' : getLabel2(value.title) }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-3">
            <div class="card custom_card p-2 p-xl-3 pb-0 mb-3">
                <div id="distancechart">
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-3">
            <div class="card custom_card p-2 p-xl-3 pb-0">
                <div id="weightchart">
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-3" id="doughnutChartContainer">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <div class="row">
                    <div class="col">
                        <h3 class="card_content">Timeliness</h3>
                    </div>

                    <div class="col-auto dropdown">
                        <button class="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#metricsFilterChart">
                            <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                        </button>

                        <div class="modal fade" id="metricsFilterChart" tabindex="-1" aria-labelledby="metricsFilterChartLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="metricsFilterChartLabel">Filter Options</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group mb-3">
                                        <label>Select Year</label>
                                        <select class="form-select" v-model="selectedYearChart" @change="yearSelectedChart(selectedYearChart)">
                                            <option value="All">All</option>
                                            <option :value="option" v-for="(option,index) in yearChartCollection" :key="index">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                    <hr>
                                    <div class="form-group mb-3" v-if="selectedYearChart != 'All'">
                                        <label>Select Months</label>
                                        <select class="form-select" v-model="selectedMonthChart">
                                            <option :value="option" v-for="(option,index) in monthChartCollection" :key="index">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilterChart()">Clear Filter</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" @click="performFilterChart()" data-bs-dismiss="modal">Apply Filter</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="card custom_card p-2 p-xl-3 p-xxl-5" v-if="loadPieChart">
                <div class="w-100">
                    <Doughnut ref="chartdoughnut" :data="pieData" :options="pieOptions" />
                </div>

                <div class="legend-table mt-3">
                <!-- Loop through legend items -->
                    <div v-for="(label, index) in pieData.labels" :key="index" class="legend-cell" @click="toggleChartData(this,index)">
                        <div class="legend-item">
                            <span class="legend-color" :style="{ backgroundColor: pieData.datasets[0].backgroundColor[index % pieData.datasets[0].backgroundColor.length] }"></span>
                            <span class="legend-text">{{ label }} ( {{ formatNumberChart(pieData?.datasets[0]?.data[index]) }} %)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-3" id="qualityChartContainer">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <div class="row">
                    <div class="col">
                        <h3 class="card_content">Image Rating</h3>
                    </div>

                    <div class="col-auto dropdown">
                        <button class="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#metricsFilterChart2">
                            <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                        </button>

                        <div class="modal fade" id="metricsFilterChart2" tabindex="-1" aria-labelledby="metricsFilterChart2Label" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="metricsFilterChart2Label">Filter Options</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group mb-3">
                                        <label>Select Year</label>
                                        <select class="form-select" v-model="selectedYearChart2" @change="yearSelectedChart2(selectedYearChart2)">
                                            <option value="All">All</option>
                                            <option :value="option" v-for="(option,index) in yearChartCollection2" :key="index">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                    <hr>
                                    <div class="form-group mb-3" v-if="selectedYearChart2 != 'All'">
                                        <label>Select Months</label>
                                        <select class="form-select" v-model="selectedMonthChart2">
                                            <option :value="option" v-for="(option,index) in monthChartCollection2" :key="index">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilterChart2()">Clear Filter</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" @click="performFilterChart2()" data-bs-dismiss="modal">Apply Filter</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="card custom_card p-2 p-xl-3 p-xxl-5" v-if="loadPieChart">
                <div class="w-100">
                    <Doughnut ref="chartdoughnutquality" :data="pieData2" :options="pieOptions" />
                </div>

                <div class="legend-table mt-3">
                <!-- Loop through legend items -->
                    <div v-for="(label, index) in pieData2.labels" :key="index" class="legend-cell" @click="toggleChartData2(this,index)">
                        <div class="legend-item">
                            <span class="legend-color" :style="{ backgroundColor: pieData2.datasets[0].backgroundColor[index % pieData2.datasets[0].backgroundColor.length] }"></span>
                            <span class="legend-text">{{ label }} ( {{ formatNumberChart(pieData2?.datasets[0]?.data[index]) }} %)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 col-xl-6 mb-3">
            <div class="card custom_card p-2 p-xl-3 pb-0">
                <div id="weightchart">
                </div>
            </div>
        </div> -->

        <!-- <div class="col-12 col-xl-6 mb-3" v-if="loadBarChart">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <h3 class="card_content mb-5">
                    {{ completedCollectionText }}
                </h3>
                <Bar
                    id="my-chart-id"
                    :options="barChartOptions1"
                    :data="barChartData1"
                    ref="barChart1"
                />
            </div>
        </div> -->

        <!-- <div class="col-12 col-xl-6 mb-3" v-if="loadBarChart">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <h3 class="card_content mb-5">
                    {{ distanceText }}
                </h3>
                <Bar
                    id="my-chart-id"
                    :options="barChartOptions2"
                    :data="barChartData2"
                />
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-3" v-if="loadBarChart">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <h3 class="card_content mb-5">
                    {{ weightText }}
                </h3>
                <Bar
                    id="my-chart-id"
                    :options="barChartOptions3"
                    :data="barChartData3"
                />
            </div>
        </div> -->
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
</template>

<script>
    import { Doughnut } from 'vue-chartjs';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PieController, ArcElement  } from 'chart.js';
    import NumberAnimation from "vue-number-animation";
    import { useMyStore } from '@/store';
    import ApexCharts from 'apexcharts';
    import VueMultiselect from 'vue-multiselect';
    import jsPDF from 'jspdf';
    import html2canvas from 'html2canvas';

    import axios from 'axios';
    

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PieController, ArcElement )

    export default {
        name: 'BarChart',
        components: { Doughnut,NumberAnimation,VueMultiselect },
        data() {
            return {
                loadBarChart: false,
                noDataFound: false,
                loadPieChart: true,
                myStore: useMyStore(),
                finalNumber: 322,
                downloadOptions: false,
                monthOptions: [
                    { id: 1, name: 'Option 1' },
                    { id: 2, name: 'Option 2' },
                    { id: 3, name: 'Option 3' },
                    // Add more options as needed
                ],
                selectedMonths: [],
                chartDataBarLine: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                    {
                        label: 'Bar Dataset',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1,
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: 'Line Dataset',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        fill: false,
                        data: [25, 35, 45, 55, 65, 75, 85]
                    }
                    ]
                },
                chartOptionsBarLine: {
                    responsive: true,
                    scales: {
                    y: {
                        beginAtZero: true
                    }
                    }
                },
                chartData: {
                    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                    datasets: [{
                        data: [400000, 200000, 120000, 300000, 250000, 180000, 350000, 400000, 300000, 380000, 280000, 320000],
                        backgroundColor: '#06CCCC'
                    }]
                },
                barChartData1: {
                    labels: [],
                    datasets: [{
                        data: [],
                        backgroundColor: '#06CCCC'
                    }]
                },
                barChartOptions1: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                // stepSize: 1000,
                                // @ts-ignore
                                // callback: function(value) {
                                //     return value / 1000 + 'k'; // Display values in 'k' format
                                // }
                            }
                        }
                    }
                },
                barChartData2: {
                    labels: [],
                    datasets: [{
                        data: [],
                        backgroundColor: '#06CCCC'
                    }]
                },
                barChartOptions2: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                // stepSize: 1000,
                                // @ts-ignore
                                // callback: function(value) {
                                //     return value / 1000 + 'k'; // Display values in 'k' format
                                // }
                            }
                        }
                    }
                },
                barChartData3: {
                    labels: [],
                    datasets: [{
                        data: [],
                        backgroundColor: '#06CCCC'
                    }]
                },
                barChartOptions3: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                // stepSize: 1000,
                                // @ts-ignore
                                // callback: function(value) {
                                //     return value / 1000 + 'k'; // Display values in 'k' format
                                // }
                            }
                        }
                    }
                },
                chartOptions: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 100000,
                                // @ts-ignore
                                callback: function(value) {
                                    return value / 1000 + 'k'; // Display values in 'k' format
                                }
                            }
                        }
                    }
                },
                pieData: {
                    labels: ['On Time', 'Late'],
                    datasets: [{
                        backgroundColor: ['#00D8FF', '#DD1B16'],
                        data: [50, 50]
                    }]
                },
                pieData2: {
                    labels: ['Excellent Images', 'Good Images', 'Poor Images'],
                    datasets: [{
                        backgroundColor: ['#00D8FF', '#41B883', '#DD1B16'],
                        data: [50, 25, 25]
                    }]
                },
                pieOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false // Hide the legend labels
                        }
                    },
                },
                recycle_option_title: 'THIS MONTH',
                allCardDetails: [],
                entireDetails: [],
                tempCardDetails: [],
                subTitle: '',
                isLoading: false,
                byYearData: [],
                yearCollection: [],
                monthCollection: [],
                default_selection: 'All',
                month_selected: '',
                selectedYear: '',
                selectedMonth: '',
                distanceText: '',
                completedCollectionText: '',
                weightText: '',
                chart: null,
                chart2: null,
                selectedYearChart: 'All',
                selectedMonthChart: '',
                monthChartCollection: [],
                yearChartCollection: [],

                selectedYearChart2: 'All',
                selectedMonthChart2: '',
                monthChartCollection2: [],
                yearChartCollection2: [],
            }
        },
        methods: {
            formatNumberChart(val){
                let value = val.toString().replace(/[^0-9.]/g, '');
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';
                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);
                if(val > 0) {
                    return integerPart + (decimalPart ? '.' + decimalPart : '');
                }
                return integerPart;
            },
            checkMonthSelected() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                if (this.selectedMonths.includes(currentMonthFullName) && this.default_selection == currentYear) {
                    if (this.selectedMonths.length == 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            checkLastMonthSelected() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                if (this.selectedMonths.includes(lastMonthFullName) && this.default_selection == lastMonthYear) {
                    if (this.selectedMonths.length == 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            fetchThisMonthData() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                this.selectedMonths = [];
                this.selectedMonths.push(currentMonthFullName);
                this.default_selection = currentYear;

                this.tempCardDetails = [];
                this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                    this.tempCardDetails.push({title: item.title, value: 0});
                });

                Object.entries(this.byYearData).forEach(([key,value]) => {
                    if (value.title == currentYear) {
                        Object.entries(value.value).forEach(([key,value1]) => {
                            if (value1.title == currentMonthFullName) {
                                this.tempCardDetails = value1.value;
                            }
                            console.log(key);
                        });
                    }
                    console.log(key);
                });
                this.allCardDetails = [];
                Object.entries(this.tempCardDetails).forEach(([key, value]) => {
                    this.allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                    console.log(key);
                });
            },
            lastMonthData() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                this.selectedMonths = [];
                this.selectedMonths.push(lastMonthFullName);
                this.default_selection = lastMonthYear;

                this.tempCardDetails = [];
                this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                    this.tempCardDetails.push({title: item.title, value: 0});
                });
                Object.entries(this.byYearData).forEach(([key,value]) => {
                    if (value.title == lastMonthYear) {
                        Object.entries(value.value).forEach(([key,value1]) => {
                            if (value1.title == lastMonthFullName) {
                                this.tempCardDetails = value1.value;
                                console.log(key);
                            }
                        });
                    }
                    console.log(key);
                });
                this.allCardDetails = [];
                Object.entries(this.tempCardDetails).forEach(([key, value]) => {
                    this.allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                    console.log(key);
                });
            },
            toggleChartData(button,index) {
                if (this.$refs.chartdoughnut) {
                    console.log("Chart doughnut reference found", this.$refs.chartdoughnut);
                    const chart = this.$refs.chartdoughnut.chart;
                    const meta = chart.getDatasetMeta(0);
                    console.log('meta', meta, index);
                    const current = meta.data[index];
                    current.hidden = !current.hidden;
                    chart.update();
                } else {
                    console.log("Chart doughnut reference not found");
                }
            },
            toggleChartData2(button,index) {
                if (this.$refs.chartdoughnutquality) {
                    console.log("Chart doughnut reference found", this.$refs.chartdoughnutquality);
                    const chart = this.$refs.chartdoughnutquality.chart;
                    const meta = chart.getDatasetMeta(0);
                    console.log('meta', meta, index);
                    const current = meta.data[index];
                    current.hidden = !current.hidden;
                    chart.update();
                } else {
                    console.log("Chart doughnut reference not found");
                }
            },
            formatNumber(value) {
                // Convert the value to an integer
                return Math.floor(value);
            },
            formatNumberPay(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val){
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return integerPart
            },
            formatNumberDecimal(value) {
                if (Number.isInteger(value)) {
                    return this.formatNumberWithCommas(value);
                } else {
                    let formattedValue = value.toFixed(1);
                    return this.formatNumberWithCommas(formattedValue);
                }
            },
            formatNumberWithCommas(value) {
                let formattedValue = value.toString();
                let parts = formattedValue.split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                formattedValue = parts.join('.');

                return formattedValue;
            },
            getLabel(key) {
                // const words = key.split(/(?=[A-Z])/);
                // const label = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                // return label;

                const index = key.indexOf('(');
                if (index !== -1) {
                    // If parentheses are found, return the substring before the parentheses
                    return key.substring(0, index).trim();
                } else {
                    // If no parentheses are found, return the original key
                    return key;
                }
            },
            getLabel2(key) {
                const startIndex = key.indexOf('(');
                const endIndex = key.indexOf(')');
                if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                    // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                    return key.substring(startIndex + 1, endIndex).trim() + ' ';
                } else {
                    // If no parentheses are found or they are in the wrong order, return an empty string
                    return '';
                }
            },
            getLabel3(key) {
                const startIndex = key.indexOf('(');
                const endIndex = key.indexOf(')');
                if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                    // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                    return key.substring(startIndex + 1, endIndex).trim();
                } else {
                    // If no parentheses are found or they are in the wrong order, return an empty string
                    return '';
                }
            },
            performFilterChart() {
                // this.loadPieChart = false;
                if (this.selectedYearChart == 'All') {
                    this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                        if (item.fieldName == 'onTimePercentage') {
                            if (item.value != null) {
                                this.pieData.datasets[0].data[0] = item.value;
                                this.pieData.datasets[0].data[1] = 100 - item.value;
                            } else {
                                this.pieData.datasets[0].data[0] = 0;
                                this.pieData.datasets[0].data[1] = 0;
                            }
                        }
                    });
                } else {
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == this.selectedYearChart) {
                            Object.entries(value.value).forEach(([key,value1]) => {
                                if (value1.title == this.selectedMonthChart) {
                                    Object.entries(value1.value).forEach(([key,value2]) => {
                                        if (value2.fieldName == 'onTimePercentage') {
                                            if (value2.value != null) {
                                                this.pieData.datasets[0].data[0] = value2.value;
                                                this.pieData.datasets[0].data[1] = 100 - value2.value;
                                            } else {
                                                this.pieData.datasets[0].data[0] = 0;
                                                this.pieData.datasets[0].data[1] = 0;
                                            }
                                        }
                                        console.log(key,value2);
                                    });
                                }
                                console.log(key,value1);
                            });
                        }
                        console.log(key,value);
                    });
                }
                // this.loadPieChart = true;
                if (this.$refs.chartdoughnut) {
                    const chart = this.$refs.chartdoughnut.chart;
                    chart?.update();
                } else {
                    console.log('inside else', this.$refs.chartdoughnut);
                }
                console.log('pie chart data', this.pieData);
            },
            performFilterChart2() {
                let excelect_image = 0;
                let good_image = 0;
                let poor_image = 0;
                // this.loadPieChart = false;
                if (this.selectedYearChart2 == 'All') {
                    this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                        if (item.fieldName == 'noOfExcelentJobs') {
                            if (item.value != null) {
                                excelect_image = item.value;
                            } else {
                                excelect_image = 0;
                            }
                        }
                        if (item.fieldName == 'noOfGoodJobs') {
                            if (item.value != null) {
                                good_image = item.value;
                            } else {
                                good_image = 0;
                            }
                        }
                        if (item.fieldName == 'noOfPoorJobs') {
                            if (item.value != null) {
                                poor_image = item.value;
                            } else {
                                poor_image = 0;
                            }
                        }
                    });
                } else {
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == this.selectedYearChart2) {
                            Object.entries(value.value).forEach(([key,value1]) => {
                                if (value1.title == this.selectedMonthChart2) {
                                    Object.entries(value1.value).forEach(([key,value2]) => {
                                        // if (value2.fieldName == 'onTimePercentage') {
                                        //     if (value2.value != null) {
                                        //         this.pieData.datasets[0].data[0] = value2.value;
                                        //         this.pieData.datasets[0].data[1] = 100 - value2.value;
                                        //     } else {
                                        //         this.pieData.datasets[0].data[0] = 0;
                                        //         this.pieData.datasets[0].data[1] = 0;
                                        //     }
                                        // }
                                        if (value2.fieldName == 'noOfExcelentJobs') {
                                            if (value2.value != null) {
                                                excelect_image = value2.value;
                                            } else {
                                                excelect_image = 0;
                                            }
                                        }
                                        if (value2.fieldName == 'noOfGoodJobs') {
                                            if (value2.value != null) {
                                                good_image = value2.value;
                                            } else {
                                                good_image = 0;
                                            }
                                        }
                                        if (value2.fieldName == 'noOfPoorJobs') {
                                            if (value2.value != null) {
                                                poor_image = value2.value;
                                            } else {
                                                poor_image = 0;
                                            }
                                        }
                                        console.log(key,value2);
                                    });
                                }
                                console.log(key,value1);
                            });
                        }
                        console.log(key,value);
                    });
                }
                let total_images = excelect_image + good_image + poor_image;

                let excellent_percentage = (excelect_image / total_images) * 100;
                let good_percentage = (good_image / total_images) * 100;
                let poor_percentage = (poor_image / total_images) * 100;

                this.pieData2.datasets[0].data[0] = excellent_percentage;
                this.pieData2.datasets[0].data[1] = good_percentage;
                this.pieData2.datasets[0].data[2] = poor_percentage;
                // this.loadPieChart = true;
                if (this.$refs.chartdoughnutquality) {
                    const chart = this.$refs.chartdoughnutquality.chart;
                    chart?.update();
                } else {
                    console.log('inside else', this.$refs.chartdoughnutquality);
                }
                console.log('pie chart data', this.pieData);
                console.log('percentage:', excellent_percentage, good_percentage, poor_percentage);
            },
            clearFilterChart() {
                Object.entries(this.byYearData).forEach(([key,value]) => {
                    this.selectedYearChart = value.title;
                    this.yearChartCollection.push(value.title);
                    console.log(key,value);
                });
                this.yearSelectedChart(this.selectedYearChart);
                this.performFilterChart();
            },
            clearFilterChart2() {
                Object.entries(this.byYearData).forEach(([key,value]) => {
                    this.selectedYearChart2 = value.title;
                    this.yearChartCollection2.push(value.title);
                    console.log(key,value);
                });
                this.yearSelectedChart2(this.selectedYearChart2);
                this.performFilterChart2();
            },
            clearFilter() {
                Object.entries(this.byYearData).forEach(([key,value]) => {
                    this.yearCollection.push(value.title);
                    this.default_selection = value.title;
                    console.log(key,value);
                });

                const allData = this.myStore.partnerSummary.data.data[0].value;
                const allCardDetails = [];

                Object.entries(allData).forEach(([key, value]) => {
                    allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                    console.log(key,value);
                });
                this.allCardDetails = allCardDetails;
                this.yearSelected(this.default_selection);
            },
            performFilter(){
                console.log(this.selectedMonths);
                this.tempCardDetails = [];
                this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                    this.tempCardDetails.push({title: item.title, value: 0});
                });
                if (this.default_selection != 'All') {
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == this.default_selection) {
                            Object.entries(value.value).forEach(([key,value]) => {
                                if (this.selectedMonths.includes(value.title)) {
                                    this.tempCardDetails = this.tempCardDetails.filter((item)=>{
                                        const temp_value_holder = value.value.find(item2 => item2.title == item.title);
                                        item.value += temp_value_holder.value; 
                                        return item;
                                    });
                                    console.log(key);
                                }
                            });
                        }
                        console.log(key);
                    });
                    this.allCardDetails = [];
                    Object.entries(this.tempCardDetails).forEach(([key, value]) => {
                        this.allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                        console.log(key);
                    });
                }

                document.getElementById('metricsFilter').style.display = 'none';
                const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                modalBackdrops.forEach(modalBackdrop => {
                    modalBackdrop.remove();
                });
            },
            yearSelected(year){
                this.default_selection = year;
                this.selectedMonths = [];
                this.isLoading = true;
                if (year == 'All') {
                    this.monthCollection = [];
                    this.allCardDetails = this.entireDetails;
                    this.isLoading = false;
                } else {
                    this.monthCollection = [];
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == year) {
                            Object.entries(value.value).forEach(([key,value]) => {
                                this.monthCollection.push(value.title);
                                this.month_selected = value.title;
                                this.tempCardDetails = value.value;
                                console.log(key);
                            });
                        }
                        console.log(key);
                    });
                    this.allCardDetails = [];
                    Object.entries(this.tempCardDetails).forEach(([key, value]) => {
                        this.allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                        console.log(key);
                    });
                    this.isLoading = false;
                    this.selectedMonths.push(this.month_selected)
                }
            },
            yearSelectedChart(year){
                this.selectedYearChart = year;
                this.monthChartCollection = [];

                if (this.selectedYearChart == 'All') {
                    this.monthChartCollection = [];
                } else {
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == year) {
                            Object.entries(value.value).forEach(([key,value]) => {
                                this.monthChartCollection.push(value.title);
                                this.selectedMonthChart = value.title;
                                console.log(key);
                            });
                        }
                        console.log(key);
                    });
                }
            },
            yearSelectedChart2(year){
                this.selectedYearChart2 = year;
                this.monthChartCollection2 = [];

                if (this.selectedYearChart2 == 'All') {
                    this.monthChartCollection2 = [];
                } else {
                    Object.entries(this.byYearData).forEach(([key,value]) => {
                        if (value.title == year) {
                            Object.entries(value.value).forEach(([key,value]) => {
                                this.monthChartCollection2.push(value.title);
                                this.selectedMonthChart2 = value.title;
                                console.log(key);
                            });
                        }
                        console.log(key);
                    });
                }
            },
            monthSelected(month) {
                this.month_selected = month;

                Object.entries(this.byYearData).forEach(([key,value]) => {
                    if (value.title == this.default_selection) {
                        Object.entries(value.value).forEach(([key,value]) => {
                            if (value.title == month) {
                                this.tempCardDetails = value.value;
                                console.log(key);
                            }
                        });
                    }
                    console.log(key);
                });
                this.allCardDetails = [];
                Object.entries(this.tempCardDetails).forEach(([key, value]) => {
                    this.allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                    console.log(key);
                });
            },
            async generatePDF() {
                this.downloadOptions = false;
                const pdf = new jsPDF('p', 'pt', 'a4');

                const headerContent = await html2canvas(document.querySelector('#top_header_logo_name'));

                // Render the HTML content into a canvas
                const distanceChart = await html2canvas(document.querySelector('#distancechart'));
                const weightChart = await html2canvas(document.querySelector('#weightchart'));
                // const doughnutChart = await html2canvas(document.querySelector('#doughnutChartContainer'));
                const cardsCollection = await html2canvas(document.querySelector('#cardsCollectionContainer'));

                // Calculate the width of the PDF
                const pdfWidth = pdf.internal.pageSize.getWidth();

                const headerContentAspectRatio = headerContent.width / headerContent.height;

                // Calculate the aspect ratio of each chart
                const distanceChartAspectRatio = distanceChart.width / distanceChart.height;
                const weightChartAspectRatio = weightChart.width / weightChart.height;
                // const doughnutChartAspectRatio = doughnutChart.width / doughnutChart.height;
                const cardsCollectionAspectRatio = cardsCollection.width / cardsCollection.height;

                const headerContentHeight = pdfWidth / headerContentAspectRatio;
                // Calculate the height of each chart image to fit within the PDF width
                const distanceChartHeight = pdfWidth / distanceChartAspectRatio;
                const weightChartHeight = pdfWidth / weightChartAspectRatio;
                // const doughnutChartHeight = pdfWidth / doughnutChartAspectRatio;
                const cardsCollectionHeight = pdfWidth / cardsCollectionAspectRatio;

                const headerImg = headerContent.toDataURL('image/png');

                // Convert the canvas content to a base64 image
                const distanceChartImg = distanceChart.toDataURL('image/png');
                const weightChartImg = weightChart.toDataURL('image/png');
                // const doughnutChartImg = doughnutChart.toDataURL('image/png');
                const cardsCollectionImg = cardsCollection.toDataURL('image/png');

                // Add borders to images
                const borderWidth = 1;
                // const borderColor = '#000'; // black

                const imageWidth = pdfWidth * 0.6; // 70% of the page width
                const imageHeight = headerContentHeight * 0.6; // 70% of the original height
                const x = (pdf.internal.pageSize.getWidth() - imageWidth) / 2; // Center horizontally
                const y = 10 + borderWidth; // Set vertical position
                pdf.addImage(headerImg, 'PNG', x, y, imageWidth - 2 * borderWidth, imageHeight - 2 * borderWidth);
                pdf.rect(10, headerContentHeight - 10, pdfWidth - 20, distanceChartHeight, 'S', 'D');
                pdf.addImage(distanceChartImg, 'PNG', 10 + borderWidth,headerContentHeight - 10 + borderWidth, pdfWidth - 20 - 2 * borderWidth, distanceChartHeight - 2 * borderWidth);

                // pdf.addPage();
                // pdf.addImage(headerImg, 'PNG', 10 + borderWidth, 10 + borderWidth, pdfWidth - 20 - 2 * borderWidth, distanceChartHeight - 2 * borderWidth);
                pdf.rect(10, headerContentHeight + distanceChartHeight + 10, pdfWidth - 20, weightChartHeight, 'S', 'D');
                pdf.addImage(weightChartImg, 'PNG', 10 + borderWidth, headerContentHeight + distanceChartHeight + 10 + borderWidth, pdfWidth - 20 - 2 * borderWidth, weightChartHeight - 2 * borderWidth);

                // Add a new page for the doughnut chart
                // pdf.addPage();
                // pdf.addImage(doughnutChartImg, 'PNG', 10, 10, pdfWidth - 20, doughnutChartHeight);

                pdf.addPage();
                pdf.addImage(headerImg, 'PNG', x, y, imageWidth - 2 * borderWidth, imageHeight - 2 * borderWidth);
                pdf.rect(10, headerContentHeight - 10, pdfWidth - 20, cardsCollectionHeight, 'S', 'D');
                pdf.addImage(cardsCollectionImg, 'PNG', 10 + borderWidth, headerContentHeight - 10 + borderWidth, pdfWidth - 20 - 2 * borderWidth, cardsCollectionHeight - 2 * borderWidth);

                // Download the PDF
                pdf.save('your-metrics.pdf');
            },
            downloadCSV() {
                this.downloadOptions = false;
                const filteredData = this.allCardDetails.map(({ title, value, fieldName }) => ({ title, value, fieldName }));
                const csvContent = this.convertToCSV(filteredData);
                this.downloadFile(csvContent, 'your-metrics.csv');
            },
            convertToCSV(jsonData) {
                const header = Object.keys(jsonData[0]).join(',') + '\n';
                const rows = jsonData.map(obj => Object.values(obj).join(',')).join('\n');
                return header + rows;
            },
            downloadFile(content, fileName) {
                const blob = new Blob([content], { type: 'text/csv' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        async mounted() {
            this.myStore.partner_dashboard.data.data.map((item)=>{
                if (item.type?.includes('tab') && item.tag == 'client_metrics') {
                    this.subTitle = item.subtitle;
                }
            });
            if (this.myStore.partnerSummary == null) {
                let user_details = localStorage.getItem('commercials_user_data');
                user_details = JSON.parse(user_details);
                console.log('user details: -> ', user_details);
                let user_id = user_details.uid;
                // await axios.get(`https://portal-litta-api.web.app/partners/users/hcq35GH7ZLYL9qCewU2xmaCnaQy2/summary`).then(response=>{
                await axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}/summary`).then(response=>{
                    this.myStore.partnerSummary = response;
                }).catch(error=>{
                    console.log(error);
                });
            }

            if (!this.myStore.partnerSummary) {
                this.noDataFound = true;
                this.$emit('triggerFunction');
            }

            var distanceOptions = {
                series: [
                    {
                        name: 'Website Blog',
                        type: 'column',
                        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
                    }, 
                    {
                        name: 'Social Media',
                        type: 'line',
                        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
                    }
                ],
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                title: {
                    text: 'Traffic Sources'
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [{
                    title: {
                        text: 'Website Blog',
                    },
                    labels: {
                        formatter: function(value) {
                            // Format numbers with comma separator for values above 1000
                            if (value >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            return value;
                        }
                    }
                
                }, {
                    opposite: true,
                    title: {
                        text: 'Social Media'
                    },
                    labels: {
                        formatter: function(value) {
                            // Format numbers with comma separator for values above 1000
                            if (value >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            return value;
                        }
                    }
                }]
            };

            var weightOptions = {
                series: [
                    {
                        name: 'Website Blog',
                        type: 'column',
                        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
                    }, 
                    {
                        name: 'Social Media',
                        type: 'line',
                        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
                    }
                ],
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                title: {
                    text: 'Traffic Sources'
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [{
                    title: {
                        text: 'Website Blog',
                    },
                    labels: {
                        formatter: function(value) {
                            // Format numbers with comma separator for values above 1000
                            if (value >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            return value;
                        }
                    }
                
                }, {
                    opposite: true,
                    title: {
                        text: 'Social Media'
                    },
                    labels: {
                        formatter: function(value) {
                            // Format numbers with comma separator for values above 1000
                            if (value >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            return value;
                        }
                    }
                }]
            };

            // console.log(this.myStore.partnerSummary.data.all);
            this.byYearData = this.myStore.partnerSummary.data.data[1].value;
            Object.entries(this.byYearData).forEach(([key,value]) => {
                this.yearCollection.push(value.title);
                this.default_selection = value.title;
                this.selectedYearChart = value.title;
                this.selectedYearChart2 = value.title;
                this.yearChartCollection.push(value.title);
                this.yearChartCollection2.push(value.title);
                console.log(key,value);
            });
            console.log('year colection: ', this.yearCollection);

            const allData = this.myStore.partnerSummary.data.data[0].value;
            const allCardDetails = [];

            Object.entries(allData).forEach(([key, value]) => {
                allCardDetails.push({ title: value.title, value: value.value, fieldName: value.fieldName });
                console.log(key,value);
            });

            this.entireDetails = allCardDetails;

            this.isLoading = false;
            this.$emit('triggerFunction');

            this.allCardDetails = allCardDetails;

            this.yearSelected(this.default_selection);
            this.yearSelectedChart(this.selectedYearChart);
            this.yearSelectedChart2(this.selectedYearChart2);
            this.performFilterChart();
            this.performFilterChart2();
            this.myStore.partnerSummary.data.data[0].value.map((item)=>{
                if (item.fieldName == 'completedCollectionsCount') {
                    this.completedCollectionText = item.title;
                    distanceOptions.yaxis[1].title.text = item.title;
                    weightOptions.yaxis[1].title.text = item.title;
                }
                if (item.fieldName == 'totalDistance') {
                    this.distanceText = item.title;
                    distanceOptions.title.text = item.title;
                    distanceOptions.yaxis[0].title.text = item.title;
                }
                if (item.fieldName == 'totalVolume') {
                    this.weightText = item.title;
                    weightOptions.title.text = item.title;
                    weightOptions.yaxis[0].title.text = item.title;
                }
            });

            distanceOptions.title.text = 'Total Distance and Jobs by Month';
            weightOptions.title.text = 'Total Earnings and Jobs by Month';
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const previousYear = currentYear - 1;
            const currentMonthIndex = currentDate.getMonth();
            const fullMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
            const currentMonthName = monthNames[currentMonthIndex];
            console.log('Previous Year:', previousYear);
            console.log('Current Month:', currentMonthName);
            console.log('checking dataset: ', this.barChartData1, this.chartData);
            // const chart1 = this.$refs.barChart1.chart;
            // chart1.update();
            this.loadBarChart = true;

            distanceOptions.labels = [];
            weightOptions.labels = [];

            distanceOptions.series[0].data = [];
            distanceOptions.series[1].data = [];
            distanceOptions.series[0].name = this.distanceText;
            distanceOptions.series[1].name = this.completedCollectionText;

            weightOptions.series[0].data = [];
            weightOptions.series[1].data = [];
            weightOptions.series[0].name = this.weightText;
            weightOptions.series[1].name = this.completedCollectionText;
            for (let i = currentMonthIndex; i < 12; i++) {
                const label = `01 ${monthNames[i]} ${previousYear.toString()}`;
                distanceOptions.labels.push(label);
                weightOptions.labels.push(label);
                
                let temp_year_data = this.myStore.partnerSummary.data.data[1].value.find(item2 => item2.fieldName == previousYear.toString());
                if (temp_year_data) {
                    let temp_month_data = temp_year_data.value.find(item2 => item2.fieldName == fullMonthNames[i]);
                    if (temp_month_data) {
                        temp_month_data.value.map((item3)=>{
                            if (item3.fieldName == 'completedCollectionsCount'){
                                distanceOptions.series[1].data.push(parseFloat(item3.value.toFixed(2)));
                                weightOptions.series[1].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                            if (item3.fieldName == 'totalDistance'){
                                distanceOptions.series[0].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                            if (item3.fieldName == 'totalVolume'){
                                weightOptions.series[0].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                        });
                    } else {
                        console.log('inside else ', previousYear.toString());
                        distanceOptions.series[1].data.push(0);
                        weightOptions.series[1].data.push(0);

                        distanceOptions.series[0].data.push(0);
                        weightOptions.series[0].data.push(0);
                    }
                    
                } else {
                    distanceOptions.series[1].data.push(0);
                    weightOptions.series[1].data.push(0);

                    distanceOptions.series[0].data.push(0);
                    weightOptions.series[0].data.push(0);
                }
            }

            for (let i = 0; i <= currentMonthIndex; i++) {
                const label = `01 ${monthNames[i]}, ${currentDate.getFullYear()}`;
                distanceOptions.labels.push(label);
                weightOptions.labels.push(label);
                let temp_year_data = this.myStore.partnerSummary.data.data[1].value.find(item2 => item2.fieldName == currentDate.getFullYear().toString());
                if (temp_year_data) {
                    let temp_month_data = temp_year_data.value.find(item2 => item2.fieldName == fullMonthNames[i]);
                    if (temp_month_data) {
                        temp_month_data.value.map((item3)=>{
                            if (item3.fieldName == 'completedCollectionsCount'){
                                distanceOptions.series[1].data.push(parseFloat(item3.value.toFixed(2)));
                                weightOptions.series[1].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                            if (item3.fieldName == 'totalDistance'){
                                distanceOptions.series[0].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                            if (item3.fieldName == 'totalVolume'){
                                weightOptions.series[0].data.push(parseFloat(item3.value.toFixed(2)));
                            }
                        });
                    } else {
                        distanceOptions.series[1].data.push(0);
                        weightOptions.series[1].data.push(0);

                        distanceOptions.series[0].data.push(0);
                        weightOptions.series[0].data.push(0);
                    }
                } else {
                    console.log('inside else -> ', currentDate.getFullYear().toString());
                    distanceOptions.series[1].data.push(0);
                    weightOptions.series[1].data.push(0);

                    distanceOptions.series[0].data.push(0);
                    weightOptions.series[0].data.push(0);
                }
            }

            this.chart = new ApexCharts(document.querySelector("#distancechart"), distanceOptions);
            this.chart.render();

            this.chart2 = new ApexCharts(document.querySelector("#weightchart"), weightOptions);
            this.chart2.render();
        },
        beforeUnmount() {
            if (this.chart) {
                this.chart.destroy();
            }
            if (this.chart2) {
                this.chart2.destroy();
            }
        },
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }

    .main_text span{
        color: #06CCCC;
    }

    .card_title{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .options_drop_down{
        background-color: #F7FAFC;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .legend-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* Align legend items to the left */
        align-items: center;
    }

    .legend-cell {
        width: 50%; /* Each legend item takes full width */
        text-align: left; /* Align legend item text to the left */
        padding: 5px;
        border-bottom: 1px solid #ccc;
    }

    .legend-item {
        display: flex;
        align-items: center;
    }

    .legend-color {
        width: 20px; /* Adjust as needed */
        height: 10px; /* Adjust as needed */
        margin-right: 5px; /* Adjust as needed */
    }

    .hidden {
        text-decoration: line-through;
    }

    .legend-text {
        font-size: 13px; /* Adjust as needed */
    }

    .btn-white{
        background: #fff;
        border: 1px solid #000;
    }

    .btn-white::after{
        display: none;
    }

    .dropdown-menu{
        right: 0;
    }

    .subtitle_text{
        margin-bottom: 0;
        align-content: center;
    }

    .quick_access_button{
        background: rgb(235, 235, 235);
        border: none;
        padding: 0px 20px;
        border-radius: 5px;
    }

    .quick_access_button.active{
        border: 2px solid #7B37EF;
    }

    @media (min-width: 1400px) {
        .legend-text {
            font-size: 14px; /* Adjust as needed */
        }
    }
</style>