<template>
    <div class="p-3 p-xl-5 pt-2 pb-2 pt-xl-2 pb-xl-2 footer_body" v-if="showDiv">
        <p class="main_title">
            {{ main_text }}
        </p>
        <div class="tabs_collection">
            <a :href="'https://' + website" target="_blank">
                <p class="">
                    {{ website }}
                </p>
            </a>
            <p class="ms-2 me-2">
                •
            </p>
            <a :href="'mailto:' + email" class="d-flex">
                <p>
                    {{ email }}
                </p>
            </a>
            <p class="ms-2 me-2">
                •
            </p>
            <a :href="'tel:' + contact" class="d-flex">
                <p>
                    {{ contact }}
                </p>
            </a>
            <!-- <p class="ms-2 me-2">
                •
            </p>
            <router-link class="navbar-brand mb-3" to="/litta-jobs">
                <p class="">
                    {{ jobs }}
                </p>
            </router-link>
            <p class="ms-2 me-2">
                •
            </p>
            <router-link class="navbar-brand mb-3" to="/">
                <p class="">
                    {{ metrics }}
                </p>
            </router-link>
            <p class="ms-2 me-2">
                •
            </p>
            <router-link class="navbar-brand mb-3" to="/compliance-documents">
                <p class="">
                    {{ documents }}
                </p>
            </router-link>
            <p class="ms-2 me-2">
                •
            </p>
            <router-link class="navbar-brand mb-3" to="/account-info">
                <p class="">
                    {{ info }}
                </p>
            </router-link> -->
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import axios from 'axios';
    export default{
        data() {
            return {
                main_text: '',
                website: '',
                contact: '',
                email: '',
                jobs: 'LITTA JOBS',
                metrics: 'YOUR METRICS',
                documents: 'COMPLIANCE DOCUMENTATION',
                info: 'YOUR INFORMATION',
                myStore: useMyStore(),
                showDiv: true,
            }
        },
        async mounted() {
            this.handleFooterVisibility(this.myStore.showFooter);

            let temp_response = [];
            if (this.myStore.partner_dashboard == null) {
                await axios.get('https://portal-litta-api.web.app/partners/content').then(response=>{
                    this.myStore.partner_dashboard = response;
                    temp_response = this.myStore.partner_dashboard;
                }).catch(error=>{
                    console.log('error', error);
                });
            } else {
                temp_response = this.myStore.partner_dashboard;
            }
            temp_response.data.data.map((item)=>{
                console.log(item);
                if (item.type){
                    if (item.type[0] == 'footer' && item.tag == 'supply_dashboard_footer') {
                        this.main_text = item.content;
                    }
                    if (item.type[0] == 'footer' && item.tag == 'supply_dashboard_website') {
                        this.website = item.content;
                    }
                    if (item.type[0] == 'footer' && item.tag == 'supply_dashboard_phone') {
                        this.contact = item.content;
                    }
                    if (item.type[0] == 'footer' && item.tag == 'supply_dashboard_email') {
                        this.email = item.content;
                    }
                }
            });
        },
        methods: {
            handleFooterVisibility(value) {
                if (value) {
                    this.showDiv = true;
                } else {
                    this.showDiv = false;
                }
            },
        },
        watch: {
            // Watch for changes to the showFooter property in the Vuex store
            'myStore.showFooter'(newValue, oldValue) {
                console.log(oldValue);
                this.handleFooterVisibility(newValue);
            },
        },
    }
</script>

<style scoped>
    .footer_body{
        background-color: #F7FAFC;
    }

    p{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        color: #000 !important;
        text-decoration: none;
        cursor: pointer;
    }

    .tabs_collection{
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    a{
        text-decoration: none;
    }

    p:hover{
        text-decoration: underline;
    }

    .main_title:hover{
        text-decoration: none;
        cursor: auto;
    }
</style>